export enum PaymentTypes {
  Patient = 'patient',
  Insurance = 'insurance',
  Institution = 'institution',
}

export const PaymentTypeLabels: Record<PaymentTypes, string> = {
  [PaymentTypes.Patient]: 'Patient pay',
  [PaymentTypes.Insurance]: 'Insurance',
  [PaymentTypes.Institution]: 'Institution',
}

export enum PaymentErrorTypes {
  Card = 'CARD',
}

export interface IPayment {
  type: PaymentTypes
  reqId: string
}

export enum PaymentStatus {
  pending = 'pending',
  empty = '',
  complete = 'complete',
}
