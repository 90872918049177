/* RequisitionStageType const was converted to class with static members because
 * values from ORDER_STAGE_NAMES are received from BE during app initialization and that's why
 * the initial const was initialized with empty values. Solution with static members allow us to get values on the go in more
 * dynamic way
 * TODO: think about refactoring this dictionaries
 * * */
export class RequisitionStageType {
  static get ANALYSIS(): string {
    return String(ORDER_STAGE_NAMES.order_analysis)
  }

  static get APPROVED(): string {
    return String(ORDER_STAGE_NAMES.order_approved)
  }

  static get CLINICIAN_REVIEW(): string {
    return 'CLINICIAN REVIEW'
  }

  static get COMPLETE(): string {
    return String(ORDER_STAGE_NAMES.order_review)
  }

  static get IN_TRANSIT(): string {
    return String(ORDER_STAGE_NAMES.order_received)
  }

  static get KIT_PURCHASED(): string {
    return String(ORDER_STAGE_NAMES.order_kit_purchased)
  }

  static get KIT_SHIPPED(): string {
    return String(ORDER_STAGE_NAMES.order_kit_shipped)
  }

  static get PROCESSING(): string {
    return String(ORDER_STAGE_NAMES.order_processing)
  }

  static get SUBMITTED(): string {
    return String(ORDER_STAGE_NAMES.order_submitted)
  }
}

/*
const stagesCompleteCaptions was converted from plain const Record<string,string> to a object with a proxy wrapper
because it depends from RequisitionStageType and we need to keep access to properties in an indexed way
(stagesCompleteCaptions[RequisitionStageType.KIT_PURCHASED] like this) and also get the values dynamically.
 */
const stagesCompleteCaptionsBase: Record<string, string> = {}

const stagesCompleteCaptionsBaseProxy = {
  get: function (target: any, prop: any) {
    switch (prop) {
      case RequisitionStageType.KIT_PURCHASED:
        return 'Kit purchased'
      case RequisitionStageType.KIT_SHIPPED:
        return 'Kit shipped'
      case RequisitionStageType.APPROVED:
        return 'Order approved'
      case RequisitionStageType.SUBMITTED:
        return 'Order submitted'
      case RequisitionStageType.IN_TRANSIT:
        return 'Sample received'
      case RequisitionStageType.PROCESSING:
        return 'Lab processing'
      case RequisitionStageType.ANALYSIS:
        return 'Analysis and interpretation'
      case RequisitionStageType.COMPLETE:
        return 'Report released'
      case RequisitionStageType.CLINICIAN_REVIEW:
        return 'Clinician review'
    }
  },
}

export const stagesCompleteCaptions = new Proxy(stagesCompleteCaptionsBase, stagesCompleteCaptionsBaseProxy)

export enum RequisitionContentKeys {
  KitPurchased = 'KIT_PURCHASED',
  KitShipped = 'KIT_SHIPPED',
  Approved = 'APPROVED',
  Submitted = 'SUBMITTED',
  InTransit = 'IN_TRANSIT',
  Processing = 'PROCESSING',
  Analysis = 'ANALYSIS',
  CompleteReview = 'COMPLETE_REVIEW',
  CompleteReleased = 'COMPLETE_RELEASED',
  SampleFailed = 'SAMPLE_FAILED',
}
