import {ContentfulClientApi, createClient} from 'contentful'
import {noticeError} from 'services/NoticeError'

let client: ContentfulClientApi = null

export const initializeContentfulClient = (settings: Record<string, string>) => {
  if (settings?.environment_invitae_web && settings?.access_token_invitae_web && settings?.space_invitae_web) {
    client = createClient({
      accessToken: settings.access_token_invitae_web,
      environment: settings.environment_invitae_web,
      space: settings.space_invitae_web,
    })
  }
}

export const fetchRemoteContent = async (props: Record<string, string>) => {
  try {
    const entries = await client.getEntries({
      include: 5,
      limit: 1, // Limit is 1 because we should have only one result for each asset requested
      ...props,
    })

    return entries
  } catch (error) {
    noticeError(error)
    return null
  }
}
