import notificationsFactory, {INotification} from 'models/Notification'
import {Action, createAction, handleActions} from 'redux-actions'

const ADD_NOTIFICATION = 'pe-app/notifications/ADD_NOTIFICATION'
const REMOVE_NOTIFICATION = 'pe-app/notifications/REMOVE_NOTIFICATION'

export const actionTypes = {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
}

export const addNotificationAction = createAction(ADD_NOTIFICATION, (text: string, ttl: number) => ({text, ttl}))
export const removeNotificationAction = createAction(REMOVE_NOTIFICATION)

export const addNotification = (text: string, ttl?: number) => addNotificationAction(text, ttl)

export default handleActions<INotification[], any>(
  {
    [ADD_NOTIFICATION]: (state, action: Action<Partial<INotification>>) => {
      const {text, ttl} = action.payload
      const notification = notificationsFactory.create(text, ttl)

      return [...state, notification]
    },
    [REMOVE_NOTIFICATION]: (state, action: Action<number>) => {
      const notifications = state.filter(notification => notification.id !== action.payload)

      return [...notifications]
    },
  },
  [],
)
