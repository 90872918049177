import {serialize} from 'transformers/GeneticCounselingTransformer'

import {deleteRequest, getRequest, patchRequest, postRequest} from 'api'
import {API_POST_GENETIC_COUNSELING_APPOINTMENT, API_SCHEDULED_GENETIC_COUNSELING_APPOINTMENT} from 'api/urls'

export const postScheduleGeneticCounselingAppointment = async (formData: any) => {
  try {
    await postRequest(API_POST_GENETIC_COUNSELING_APPOINTMENT, serialize(formData))
  } catch (e) {
    throw new Error(e.stack || `${e.status}: ${e.statusText}`)
  }
  return {didSchedule: true}
}

export const getScheduledGeneticCounselingAppointment = async (reqid: string) => {
  try {
    return await getRequest(API_SCHEDULED_GENETIC_COUNSELING_APPOINTMENT(reqid))
  } catch (e) {
    throw new Error(e.stack || `${e.status}: ${e.statusText}`)
  }
}

export const deleteScheduledGeneticCounselingAppointment = async (reqid: string, isAdmin?: boolean) => {
  try {
    const url = API_SCHEDULED_GENETIC_COUNSELING_APPOINTMENT(reqid)
    await deleteRequest(url.toString(), isAdmin ? {admin: true} : {admin: false})
  } catch (e) {
    throw new Error(e.stack || `${e.status}: ${e.statusText}`)
  }
}

export const updateScheduledGeneticCounselingAppointment = async (reqid: string, datetime: string) => {
  try {
    await patchRequest(API_SCHEDULED_GENETIC_COUNSELING_APPOINTMENT(reqid), {datetime})
  } catch (e) {
    throw new Error(e.stack || `${e.status}: ${e.statusText}`)
  }
  return {didUpdate: true}
}
