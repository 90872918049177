export interface INotification {
  id: number
  text: string
  ttl: number
}

export const notificationsFactory = {
  create(text: string, ttl?: number): INotification {
    return {
      id: this.id++,
      text,
      ttl: ttl ? ttl : this.ttl,
    }
  },

  id: 1,
  ttl: 4000, // time to live in ms
}

export default notificationsFactory
