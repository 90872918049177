const INVITAE_BROAD_CARRIER_SCREEN = 'PR60101'
const INVITAE_BROAD_CARRIER_SCREEN_WITHOUT_X_LINKED_DISORDERS = 'PR60201'
const INVITAE_CORE_CARRIER_SCREEN = 'PR60102'
const INVITAE_CORE_CARRIER_SCREEN_WITHOUT_X_LINKED_DISORDERS = 'PR60104'
const INVITAE_COMPREHENSIVE_CARRIER_SCREEN = 'PR60100'
const INVITAE_COMPREHENSIVE_CARRIER_SCREEN_WITHOUT_X_LINKED_DISORDERS = 'PR60200'

export const ELIGIBLE_PARTIAL_CODES_FOR_INVITE_PARTNER = [
  INVITAE_BROAD_CARRIER_SCREEN,
  INVITAE_BROAD_CARRIER_SCREEN_WITHOUT_X_LINKED_DISORDERS,
  INVITAE_CORE_CARRIER_SCREEN,
  INVITAE_CORE_CARRIER_SCREEN_WITHOUT_X_LINKED_DISORDERS,
  INVITAE_COMPREHENSIVE_CARRIER_SCREEN,
  INVITAE_COMPREHENSIVE_CARRIER_SCREEN_WITHOUT_X_LINKED_DISORDERS,
]

export const nipsSendOutAddonsWithSexChromosomesProductCodes = {
  nipsCore: /PR71004\.\d+\.1/,
  nipsExpanded: /PR71005\.\d+\.1/,
  twinNips: /PR71006\.\d+\.1/,
}
