import {DateTime} from 'luxon'
import {IPartnerLink} from 'models/PartnerLink'

import {OUTPUT_DATE_FORMAT} from 'constants/constants'

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
export const deserialize = (item): IPartnerLink => {
  const {
    partnerFirstName,
    partnerLastName,
    partnerEmail,
    dateModified,
    partnerChecksOutAt,
    resultsReleasedToPartnerAt,
  } = item
  return {
    dateModified: DateTime.fromSQL(dateModified).toFormat(OUTPUT_DATE_FORMAT),
    email: partnerEmail,
    firstName: partnerFirstName,
    lastName: partnerLastName,
    partnerChecksOutAt: partnerChecksOutAt ? DateTime.fromISO(partnerChecksOutAt).toFormat(OUTPUT_DATE_FORMAT) : null,
    resultsReleasedToPartnerAt: resultsReleasedToPartnerAt
      ? DateTime.fromISO(resultsReleasedToPartnerAt).toFormat(OUTPUT_DATE_FORMAT)
      : null,
  }
}

export const serialize = (item: Pick<IPartnerLink, 'firstName' | 'lastName' | 'email'>) => {
  const {firstName, lastName, email} = item
  return {
    partnerEmail: email,
    partnerFirstName: firstName,
    partnerLastName: lastName,
  }
}
