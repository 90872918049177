import {DateTime} from 'luxon'
import {IDatePicker} from 'models/DatePicker'

import {COUNTRY_US, OUTPUT_DATE_FORMAT, OUTPUT_DATE_INT} from 'constants/constants'

export const shortFormat = (date: string) => {
  const [, year, month, day] = date.match(/(\d{4})-(\d{2})-(\d{2})/)
  return `${month}/${day}/${year}`
}

export const convertDate = (userCountryCode: string, dateCreated: string) => {
  if (!dateCreated) {
    return ''
  }

  const isUS = userCountryCode === COUNTRY_US
  const formattedDate = dateCreated && dateCreated.length > 10 ? shortFormat(dateCreated) : dateCreated
  return DateTime.fromFormat(formattedDate, OUTPUT_DATE_FORMAT).toFormat(isUS ? OUTPUT_DATE_FORMAT : OUTPUT_DATE_INT)
}

export const getPastProps = (): IDatePicker => {
  const currentDate = new Date()
  const fromDate = new Date(1900, 0)
  const toDate = new Date(currentDate.getFullYear(), currentDate.getMonth())
  return {
    disableDaysAfter: currentDate,
    fromDate,
    toDate,
  }
}

export const dateDifferenceInDays = (startRaw: string, endRaw: string): number => {
  const startDate = DateTime.fromFormat(convertDate(COUNTRY_US, startRaw), OUTPUT_DATE_FORMAT)
  const endDate = DateTime.fromFormat(convertDate(COUNTRY_US, endRaw), OUTPUT_DATE_FORMAT)
  const luxonDateDifference = endDate.diff(startDate, 'days')
  return luxonDateDifference.days
}

export const isDateInPast = (dateRaw: DateTime): boolean => {
  return dateRaw.diffNow('hours').hours < 0
}
