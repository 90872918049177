import {Dispatch} from 'react'
import {addNotification} from 'ducks/notifications'
import {IPatientConsentPreferences, IPatientsData, IPatientsStore} from 'models/PatientPreferences'
import IStore from 'models/Store'
import {Action, createAction, handleActions} from 'redux-actions'
import invitaeUrl from 'services/invitaeUrl'
import {noticeError} from 'services/NoticeError'
import {stargate} from 'services/StargateService'
import {
  deserializeList,
  deserializeListNew,
  deserializePreferences,
  serialize,
} from 'transformers/PatientPreferencesTransformer'

import {getRequest, patchRequest} from 'api'
import {API_PATIENT_PREFERENCES, API_PATIENT_PREFERENCES_VIEW, API_PATIENTS} from 'api/urls'
import {AppDispatch} from 'store/configureStore'

const base = 'pe-app/patient-preferences/'
const GET_PATIENT_PREFERENCES_LIST = `${base}GET_LIST`
const GET_PATIENT_PREFERENCES_LIST_DONE = `${base}GET_LIST_DONE`
const POST_PATIENT_PREFERENCES = `${base}POST`
const POST_PATIENT_PREFERENCES_DONE = `${base}POST_DONE`
// const PATCH_IS_VIEWED = `${base}PATCH_IS_VIEWED`
const PATCH_PATIENT_PREFERENCES_IS_VIEWED_DONE = `${base}PATCH_IS_VIEWED_DONE`

export const actionTypes = {
  GET_PATIENT_PREFERENCES_LIST,
  GET_PATIENT_PREFERENCES_LIST_DONE,
  PATCH_PATIENT_PREFERENCES_IS_VIEWED_DONE,
  POST_PATIENT_PREFERENCES,
  POST_PATIENT_PREFERENCES_DONE,
}

export default handleActions<IPatientsStore, any>(
  {
    [GET_PATIENT_PREFERENCES_LIST]: () => ({
      data: {},
      loading: true,
    }),
    [GET_PATIENT_PREFERENCES_LIST_DONE]: (_, action: Action<{data: IPatientsData}>) => {
      const {data} = action.payload

      return {
        data,
        loadedAt: new Date().getTime(),
        loading: false,
      }
    },
    [PATCH_PATIENT_PREFERENCES_IS_VIEWED_DONE]: (state, action: Action<{patientId: string}>) => {
      const {patientId} = action.payload

      if (!state.data[patientId]) {
        return state
      }

      const data = {
        ...state.data,
        [patientId]: {
          ...state.data[patientId],
          preferences: {
            ...state.data[patientId].preferences,
            isViewed: true,
          },
        },
      }

      return {
        ...state,
        data,
      }
    },
    [POST_PATIENT_PREFERENCES]: state => ({
      ...state,
      loading: true,
    }),
    [POST_PATIENT_PREFERENCES_DONE]: (state, action: Action<{patientId: string; data: IPatientConsentPreferences}>) => {
      const {patientId, data} = action.payload
      const nextData = {...state.data}
      nextData[patientId] = {...nextData[patientId], preferences: data, state: data.state}

      return {
        data: nextData,
        loadedAt: new Date().getTime(),
        loading: false,
      }
    },
  },
  {
    data: {},
    loading: false,
  },
)

const getPatientPreferencesListAction = createAction(GET_PATIENT_PREFERENCES_LIST)
const getPatientPreferencesListDoneAction = createAction(GET_PATIENT_PREFERENCES_LIST_DONE)
const postPatientPreferencesAction = createAction(POST_PATIENT_PREFERENCES)
const postPatientPreferencesDoneAction = createAction(POST_PATIENT_PREFERENCES_DONE)
export const patchPatientPreferencesIsViewedDone = createAction(PATCH_PATIENT_PREFERENCES_IS_VIEWED_DONE)

export const getPatientPreferencesList =
  (isReadAndWriteToPDSEnabled: boolean) => async (dispatch: Dispatch<AppDispatch>, getState: () => IStore) => {
    dispatch(getPatientPreferencesListAction())

    const url = invitaeUrl({
      params: {
        relationships: ['Self', 'Parent'],
      },
      url: API_PATIENTS,
    })
    let results

    const patientIds = getState().requisitions.data.map(req => parseInt(req.patient_id))

    try {
      results = isReadAndWriteToPDSEnabled
        ? await stargate.patients
            .listPatientsExternalFromPatientsController({reqrepoIds: patientIds})
            .then(response => deserializeListNew(response.data))
        : await getRequest(url).then(deserializeList)
    } catch (e) {
      noticeError(e)
      dispatch(addNotification('Server error was detected. Please try again.'))
      return
    }
    dispatch(
      getPatientPreferencesListDoneAction({
        data: results,
      }),
    )
  }

export const patchPatientPreferencesIsViewed = (patientId: string) => async (dispatch: Dispatch<AppDispatch>) => {
  try {
    await patchRequest(API_PATIENT_PREFERENCES_VIEW(patientId), {})
  } catch (e) {
    // fall down gracefully here
    noticeError(e)
    return
  }

  dispatch(patchPatientPreferencesIsViewedDone({patientId}))
}

export type TPatientPreferencesFormData = IPatientConsentPreferences & {state: string}

export const postPatientPreferences =
  (patientId: string, data: TPatientPreferencesFormData) => async (dispatch: Dispatch<AppDispatch>) => {
    dispatch(postPatientPreferencesAction(patientId))

    let results
    try {
      results = await patchRequest(API_PATIENT_PREFERENCES(patientId), serialize(data))
    } catch (e) {
      noticeError(e)
      dispatch(addNotification('Server error was detected. Please try again.'))
      return
    }

    dispatch(addNotification('Changes were successfully saved.'))

    dispatch(
      postPatientPreferencesDoneAction({
        data: deserializePreferences(results),
        loadedAt: new Date().getDate(),
        patientId,
      }),
    )
  }
