import {ReportLevelStatuses} from 'models/Requisition'
// Interfaces for CROP carrier report data in JSON format

// GENES

export type ResultGenes = IResultGene[]

export interface IResultGene {
  gene: string
  geneId: string
}

// FREQUENCY

export type ReportFrequencies = IReportFrequency[]

export interface IReportFrequency {
  ethnicity: string
  carrierFrequency: number
  detectionRate: number
  residualRisk: number
}

// VARIANTS

export enum VariantClassification {
  Pathogenic = 'PATHOGENIC',
  LikelyPathogenic = 'LIKELY_PATHOGENIC',
  PathogenicLowPenetrance = 'PATHOGENIC_LOW_PENETRANCE',
  IncreasedRiskAllele = 'INCREASED_RISK_ALLELE',
  VUS = 'VUS',
  Benign = 'BENIGN',
  LikelyBenign = 'LIKELY_BENIGN',
}

export enum VariantZygosity {
  Homozygous = 'HOMO',
  Heterozygous = 'HETERO',
  Hemizygous = 'HEMI',
  Nulli = 'NULLI',
  UnknownZ = 'UNKNOWN_Z',
  PossibleMosaic = 'POSSIBLE_MOSAIC',
}

export enum PreSimplifiedToResultsServiceVariantMap {
  HOMO = 'homozygous',
  HETERO = 'heterozygous',
  HEMI = 'hemizygous',
  NULLI = 'nullizygous',
  UNKNOWN_Z = 'unknown',
  POSSIBLE_MOSAIC = 'possibleMosaic',
}

export const VariantZygosityLabels = {
  [VariantZygosity.Homozygous]: 'Homozygous',
  [VariantZygosity.Heterozygous]: 'Heterozygous',
  [VariantZygosity.Hemizygous]: 'Hemizygous',
  [VariantZygosity.Nulli]: 'Nullizygous',
  [VariantZygosity.UnknownZ]: 'Unknown',
  [VariantZygosity.PossibleMosaic]: 'Possible mosaic',
}

export const getZygosityLabel = (zygosity: VariantZygosity | string): string => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because ... Remove this comment to see the full error message
  return VariantZygosityLabels[zygosity] || zygosity
}

export enum VariantsSource {
  reportedVariantModel = 'REPORTED_VARIANT_MODEL',
  cropResultsService = 'CROP_RESULTS_SERVICE',
}

export type ResultVariants = IResultVariant[]

export interface IResultVariant {
  classification: VariantClassification | string
  zygosity: VariantZygosity | string
  displayName: string
  geneName?: string
  aggregationType?: string
  hasClinicNotes?: boolean
  commercialTestCode?: string
  commercialTestName?: string
  name?: string
  associatedDisorders?: string
}

export enum ResultServiceZygosity {
  homozygous = 'homozygous',
  heterozygous = 'heterozygous',
  hemizygous = 'hemizygous',
  nullizygous = 'nullizygous',
  unknown = 'unknown',
  possibleMosaic = 'possible mosaic',
}

export const resultServiceZygosityMap = {
  [ResultServiceZygosity.hemizygous]: 'HEMI',
  [ResultServiceZygosity.heterozygous]: 'HETERO',
  [ResultServiceZygosity.homozygous]: 'HOMO',
  [ResultServiceZygosity.nullizygous]: 'NULLI',
  [ResultServiceZygosity.unknown]: 'UNKNOWN',
  [ResultServiceZygosity.possibleMosaic]: 'POSSIBLY_MOSAIC',
}

export interface IResultServiceVariant {
  geneName: string
  name: string
  classification: VariantClassification | string
  zygosity: ResultServiceZygosity
  associatedDisorders?: string
}

// INHERITANCE

export enum InheritancePattern {
  autosomal = 'AUTOSOMAL_RECESSIVE',
  xLinked = 'XLINKED',
}

// RESULT TYPE

export enum ResultType {
  positive = 'POSITIVE',
  negative = 'NEGATIVE',
  increasedRisk = 'INCREASED_RISK',
  potentiallyPositive = 'POTENTIALLY_POSITIVE',
  carrier = 'CARRIER',
}

export enum ReportSubtype {
  vusTestingOriginalProband = 'vus_testing_original_proband',
  standard = 'standard',
  amended = 'amended',
  reReq = 're_requisition',
  corrected = 'corrected',
  upgrade = 'upgrade',
  newSampleAmendment = 'new_sample_amendment',
}

// CONDITION RESULT TYPE

export enum ConditionResultType {
  carrier = 'CARRIER',
  positive = 'POSITIVE',
  increasedRisk = 'INCREASED_RISK',
  resultToNote = 'RESULT_TO_NOTE',
}

//  RESULTS

export interface ICarrierResults {
  result: ResultType
  results: ICarrierResult[]
}

export interface ICarrierResult {
  genes: ResultGenes
  conditionName: string
  description: string
  inheritance: InheritancePattern
  result: ConditionResultType
  frequency: ReportFrequencies
  variants: ResultVariants
  personalRisk?: boolean
}

export interface IGenesAnalyzed {
  tables?: IGenesForCategory[]
}

export interface IGenesForCategory {
  title: string
  table: IGenesConditions[]
}

export interface IGenesConditions {
  gene: string
  conditions: string
}

export interface IReportComment {
  text: string
  level: number
}

export interface IDisorderSummary {
  disorderName: string
  description: IReportComment[]
  resources: string[]
  references: string[]
}

export interface IAneuploidyResult {
  aneuploidy: string
  result: string
  isPositiveResult: boolean
  ppv: string
  npv: string
}

export interface IMicrodeletionResult {
  result: string
  isPositiveResult: boolean
  microdeletion: string
}

export interface INipsInHouseResults {
  hasPositiveFindings: boolean
  additionalComments: IReportComment[]
  primaryFindings: {
    predictedFetalSex: string
    fetalFraction: string
    reportResult: ReportLevelStatuses
  }
  disorderSummaries: IDisorderSummary[]
  aneuploidyResults: IAneuploidyResult[]
  microdeletionResults: IMicrodeletionResult[]
  reportSubtype: ReportSubtype
  amendments: IReportComment[]
}

// REPORT

export interface IReport {
  report: string
  version: number
  order: string
  patient: string
  type: string
  carrier?: ICarrierResults
  inhouseNips?: INipsInHouseResults
  genesAnalyzed?: IGenesAnalyzed
}

export const generalAneuploidyResults = [
  'Down syndrome (Trisomy 21)',
  'Edwards syndrome (Trisomy 18)',
  'Patau syndrome (Trisomy 13)',
]

export const yChromosomeResultName = 'Y Chromosome'

export default IReport
