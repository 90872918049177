import {IAsyncModel} from 'models/AsyncModel'
import TShareLinks, {IShareLink, TFormData} from 'models/ShareLinks'
import {Action, createAction, handleActions} from 'redux-actions'
import {ThunkDispatch} from 'redux-thunk'
import {noticeError} from 'services/NoticeError'
import ShareLinkTransformer from 'transformers/ShareLinkTransformer'

import {deleteRequest, getRequest, postRequest} from 'api'
import {API_DELETE_SHARE_LINK, API_FETCH_SHARE_LINKS} from 'api/urls'

type TStateModel = IAsyncModel<TShareLinks>

const basePath = 'pe-app/share-links/'
const FETCH_SHARE_LINKS = `${basePath}FETCH`
const FETCH_COMPLETE_SHARE_LINKS = `${basePath}FETCH_COMPLETE`
const POST_SHARE_LINKS = `${basePath}POST`
const POST_COMPLETE_SHARE_LINKS = `${basePath}POST_COMPLETE`
const DELETE_SHARE_LINKS = `${basePath}DELETE`
const DELETE_COMPLETE_SHARE_LINKS = `${basePath}DELETE_COMPLETE`

export const actionTypes = {
  DELETE_COMPLETE_SHARE_LINKS,
  DELETE_SHARE_LINKS,
  FETCH_COMPLETE_SHARE_LINKS,
  FETCH_SHARE_LINKS,
  POST_COMPLETE_SHARE_LINKS,
  POST_SHARE_LINKS,
}

export const fetchAction = createAction(FETCH_SHARE_LINKS)
export const fetchCompleteAction = createAction(FETCH_COMPLETE_SHARE_LINKS)
export const postAction = createAction(POST_SHARE_LINKS)
export const postCompleteAction = createAction(POST_COMPLETE_SHARE_LINKS)
export const deleteAction = createAction(DELETE_SHARE_LINKS)
export const deleteCompleteAction = createAction(DELETE_COMPLETE_SHARE_LINKS)

export default handleActions<TStateModel, any>(
  {
    [DELETE_COMPLETE_SHARE_LINKS]: (state, action: Action<string>) => {
      const data = state.data.filter(link => link.externalId !== action.payload)

      return {
        data,
        loading: false,
      }
    },
    [DELETE_SHARE_LINKS]: state => ({
      ...state,
      loading: true,
    }),
    [FETCH_COMPLETE_SHARE_LINKS]: (state, action: Action<TShareLinks>) => {
      return {
        data: action.payload,
        loading: false,
      }
    },
    [FETCH_SHARE_LINKS]: state => ({
      ...state,
      loading: true,
    }),
    [POST_COMPLETE_SHARE_LINKS]: (state, action: Action<IShareLink>) => {
      return {
        data: [...state.data, action.payload],
        loading: false,
      }
    },
    [POST_SHARE_LINKS]: state => ({
      ...state,
      loading: true,
    }),
  },
  {data: [], loading: false},
)

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const fetchShareLinks = (reqid: string) => async (dispatch: ThunkDispatch) => {
  dispatch(fetchAction())

  await getRequest(API_FETCH_SHARE_LINKS(reqid))
    .then(response => {
      const deserializedRes = ShareLinkTransformer.deserializeAll(response)
      dispatch(fetchCompleteAction(deserializedRes))
    })
    .catch(error => {
      noticeError(error)
      dispatch(fetchCompleteAction([]))
      throw new Error(`${error.status}: ${error.statusText}`)
    })
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const postShareLink = (reqid: string, data: TFormData) => async dispatch => {
  dispatch(postAction())

  await postRequest(API_FETCH_SHARE_LINKS(reqid), ShareLinkTransformer.serialize(data))
    .then(response => {
      const deserializedRes = ShareLinkTransformer.deserialize(response)
      dispatch(postCompleteAction(deserializedRes))
    })
    .catch(error => {
      noticeError(error)
      dispatch(postCompleteAction(undefined))
      throw new Error(`${error.status}: ${error.statusText}`)
    })
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const deleteShareLink = (link: IShareLink) => async dispatch => {
  dispatch(deleteAction())

  const {externalId, reqid} = link
  await deleteRequest(API_DELETE_SHARE_LINK(reqid, externalId))
    .then(() => {
      dispatch(deleteCompleteAction(link.externalId))
    })
    .catch(error => {
      noticeError(error)
      dispatch(deleteCompleteAction(undefined))
      throw new Error(`${error.status}: ${error.statusText}`)
    })
}

export {fetchShareLinks, postShareLink, deleteShareLink}
