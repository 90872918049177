// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'hump... Remove this comment to see the full error message
import {camelizeKeys, decamelizeKeys} from 'humps'
import {ISerializedData, IShareLink, TFormData} from 'models/ShareLinks'

export const serialize = (data: TFormData): ISerializedData => {
  const {firstName, lastName, province, role} = data

  return decamelizeKeys({
    providerFirstName: firstName,
    providerLastName: lastName,
    providerRole: role,
    providerState: province,
  })
}

export const deserialize: (item: unknown) => IShareLink = item => {
  const {
    providerFirstName,
    providerLastName,
    providerState,
    providerFormattedState,
    providerRole,
    externalId,
    requisition,
    activatedBy,
  } = camelizeKeys(item)

  return {
    activatedBy,
    externalId,
    firstName: providerFirstName,
    lastName: providerLastName,
    province: providerState,
    provinceFormatted: providerFormattedState,
    reqid: requisition,
    role: providerRole,
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'items' implicitly has an 'any' type.
export const deserializeAll = items => {
  return items.map(deserialize)
}

export default {
  deserialize,
  deserializeAll,
  serialize,
}
