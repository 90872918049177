// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'hump... Remove this comment to see the full error message
import {camelizeKeys} from 'humps'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import Report, {generalAneuploidyResults, IDisorderSummary} from 'models/Report'
import {IRequisition, IRequisitionReport} from 'models/Requisition'
import {removePredictedFetalSexFromString, removeSexChromosomesFromString} from 'services/StringUtils'

export const PREDICTED_FETAL_SEX_ROW_LABEL = 'Predicted fetal sex'

export type IReportTransformerProps = Pick<IRequisition, 'has_sex_reveal_video_watched'>

export default function (report: IRequisitionReport, transformerProps?: IReportTransformerProps) {
  if (!report.report_data) {
    return report
  }
  const transformedData: Report = camelizeKeys(report.report_data)

  if (transformedData.carrier) {
    // capitalize the first letter of each disorder name
    if (transformedData.carrier.results) {
      transformedData.carrier.results.forEach(result => {
        const {conditionName} = result
        result.conditionName = conditionName && conditionName.charAt(0).toUpperCase() + conditionName.slice(1)
      })
    } else {
      // prevent empty array errors WPE-502
      transformedData.carrier.results = []
    }
  } else if (!isEmpty(transformedData.inhouseNips)) {
    // We need to make sure that first elements are general conditions
    transformedData.inhouseNips.disorderSummaries = sortBy(
      transformedData.inhouseNips.disorderSummaries,
      (condition: IDisorderSummary) => !generalAneuploidyResults.includes(condition.disorderName),
    )
    transformedData.inhouseNips.aneuploidyResults.forEach(aneuploidy => {
      // WT-15215 remove "Predicted fetal sex is male|female" from result row
      aneuploidy.result = removePredictedFetalSexFromString(aneuploidy.result)
      // WT-17445 remove the (XY) and (YY) from the sex chromosome result row if aneuploidy results are negative
      if (!aneuploidy.isPositiveResult) {
        aneuploidy.result = removeSexChromosomesFromString(aneuploidy.result)
      }
      // RHPL-376 hide all values sent from CROP for “Predicted fetal sex” row until user has downloaded PDF results or
      // has viewed sex reveal video
      if (
        aneuploidy.aneuploidy.toLowerCase().trim() === PREDICTED_FETAL_SEX_ROW_LABEL.toLowerCase() &&
        !report.is_downloaded &&
        !transformerProps?.has_sex_reveal_video_watched
      ) {
        aneuploidy.result = 'Hidden until results are downloaded or sex reveal video is watched'
      }
    })
  }

  report.report_data = transformedData
  return report
}
