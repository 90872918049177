import {IFeatureFlagsData, IFeatureFlagsStore} from 'models/FeatureFlags'
import {Dispatch} from 'redux'
import {Action, createAction, handleActions} from 'redux-actions'
import {noticeError} from 'services/NoticeError'
import {stargate} from 'services/StargateService'
import {featureFlagsDeserialize} from 'transformers/FeatureFlagsTransformer'

import {FeatureFlagsContextDto} from '@invitae/stargate'

import {FEATURE_FLAG_APP_NAME, FEATURE_FLAGS_NAMES, TOGGLE_PROJECT_NAME} from 'constants/featureFlags'
import {populateFlagsWithDefaultsValues} from 'utils/featureFlags'

import {addNotification} from './notifications'

const base = 'pe-app/feature-flags/'
const GET_FEATURE_FLAGS_LIST = `${base}GET_LIST`
const GET_FEATURE_FLAGS_LIST_DONE = `${base}GET_LIST_DONE`
const GET_FEATURE_FLAGS_LIST_FAILED = `${base}GET_LIST_FAILED`

export const actionTypes = {
  GET_FEATURE_FLAGS_LIST,
  GET_FEATURE_FLAGS_LIST_DONE,
  GET_FEATURE_FLAGS_LIST_FAILED,
}

export default handleActions<IFeatureFlagsStore, any>(
  {
    [GET_FEATURE_FLAGS_LIST]: () => ({
      data: {},
      loading: true,
    }),
    [GET_FEATURE_FLAGS_LIST_DONE]: (_, action: Action<{data: IFeatureFlagsData}>) => {
      const {data} = action.payload

      return {
        data,
        loadedAt: new Date().getTime(),
        loading: false,
      }
    },
    [GET_FEATURE_FLAGS_LIST_FAILED]: (state, action: Action<{data: IFeatureFlagsData}>) => {
      const {data} = action.payload

      return {
        ...state,
        data,
        loading: false,
      }
    },
  },
  {
    data: {},
    loading: false,
  },
)

const getFeatureFlagsListAction = createAction(GET_FEATURE_FLAGS_LIST)
const getFeatureFlagsListDoneAction = createAction(GET_FEATURE_FLAGS_LIST_DONE)
const getFeatureFlagsListFailedAction = createAction(GET_FEATURE_FLAGS_LIST_FAILED)

export const getFeatureFlagsList = (context?: FeatureFlagsContextDto) => async (dispatch: Dispatch) => {
  dispatch(getFeatureFlagsListAction())

  let featureFlags

  try {
    featureFlags = await stargate.featureFlags
      .getFeatureFlags({
        appName: FEATURE_FLAG_APP_NAME,
        context,
        flagNames: [...FEATURE_FLAGS_NAMES],
        projectName: TOGGLE_PROJECT_NAME,
      })
      .then(featureFlagsDeserialize)
  } catch (e) {
    dispatch(
      getFeatureFlagsListFailedAction({
        data: populateFlagsWithDefaultsValues(FEATURE_FLAGS_NAMES),
      }),
    )
    noticeError(e)
    dispatch(addNotification('Server error was detected. Please try again.'))
    return Promise.resolve()
  }
  dispatch(
    getFeatureFlagsListDoneAction({
      data: featureFlags,
    }),
  )
}
