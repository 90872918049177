import defaultsDeep from 'lodash/defaultsDeep'
import getCookie from 'services/Cookie'
import {CREDENTIALS_SETTING} from 'services/invitaeUrl'
import {noticeError} from 'services/NoticeError'

const localCache: Record<string, unknown> = {}

export const errorCodes = {
  badRequest: 400,
  forbidden: 403,
  internalError: 500,
  notFound: 404,
  timeout: 408,
  unauthorized: 401,
}

export async function getCached(url: string, options = {}) {
  if (localCache[url]) {
    return localCache[url]
  }

  const result = await getRequest(url, options)
  localCache[url] = result
  return result
}

export function clearCache(url: string) {
  delete localCache[url]
}

export interface IExecuteResponse {
  status: number
  statusText: string
  body?: any
}

async function execute(url: string, options: any = {}): Promise<IExecuteResponse> {
  const csrfToken = getCookie('csrftoken')
  const fullOptions: any = defaultsDeep({}, options, {
    credentials: CREDENTIALS_SETTING,
    headers: {
      Authorization: '',
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
  })

  const response = await fetch(url, fullOptions)
  let responseData
  if (response.ok) {
    try {
      responseData = await response.json()
    } catch (e) {
      noticeError(e)
    }

    return responseData
  }

  const {status, statusText} = response
  let body

  try {
    body = await response.json()
  } catch (e) {
    noticeError(e)
  }

  return Promise.reject({
    body,
    status,
    statusText,
  })
}

export function getRequest(url: string, options = {}): Promise<any> {
  return execute(url, {
    ...options,
    method: 'GET',
  })
}

export function patchRequest(url: string, body: Record<string, unknown>, options = {}): Promise<any> {
  return execute(url, {
    ...options,
    body: JSON.stringify(body),
    method: 'PATCH',
  })
}

export function deleteRequest(url: string, body = {}): Promise<any> {
  return execute(url, {body: JSON.stringify(body), method: 'DELETE'})
}

export function postRequest(url: string, body = {}, options = {}): Promise<any> {
  return execute(url, {
    ...options,
    body: JSON.stringify(body),
    method: 'POST',
  })
}
