import {ICurrentUser} from 'models/CurrentUser'

export default {
  deserialize,
  deserializeErrors,
  serialize,
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
function serialize(data) {
  return {
    first_name: data.firstName,
    is_hcp: data.isHcp,
    last_name: data.lastName,
    userprofile: {
      contact_info: {
        address_1: data.address_1,
        address_2: data.address_2,
        city: data.city,
        country: data.country,
        fax: data.fax,
        phone: data.phone,
        state: data.province,
        zipcode: data.zipcode,
      },
      email_test_updates: data.subscribe,
      npi: data.npi,
      persona: data.persona,
      practice: data.practiceName,
      specialty: data.specialty,
      subspecialty: data.subspecialty ? data.subspecialty.join(', ') : '',
    },
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
function deserialize(data) {
  const userprofile = data.userprofile
  const contactInfo = userprofile.contact_info
  const user: ICurrentUser = {
    address_1: contactInfo.address_1,
    address_2: contactInfo.address_2,
    city: contactInfo.city,
    country: contactInfo.country,
    email: data.email,
    fax: contactInfo.fax,
    firstName: data.first_name,
    hasRequisitions: data.user_has_requisitions,
    id: data.id,
    isHcp: userprofile.role === 'physician',
    lastName: data.last_name,
    npi: userprofile.npi,
    persona: userprofile.persona,
    phone: contactInfo.phone,
    practiceName: userprofile.practice === null ? '' : userprofile.practice,
    province: contactInfo.state,
    role: userprofile.role,
    specialty: userprofile.specialty,
    subscribe: userprofile.email_test_updates,
    subspecialty: userprofile.subspecialty ? userprofile.subspecialty.split(', ') : [],
    zipcode: contactInfo.zipcode,
  }
  user.fullName = `${user.firstName} ${user.lastName}`
  return user
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'errors' implicitly has an 'any' type.
function deserializeErrors(errors) {
  const userprofile = errors.userprofile
  const contactInfo = userprofile.contact_info ? userprofile.contact_info : {}
  return {
    address_1: contactInfo.address_1,
    address_2: contactInfo.address_2,
    city: contactInfo.city,
    country: contactInfo.country,
    fax: contactInfo.fax,
    firstName: errors.first_name,
    lastName: errors.last_name,
    npi: userprofile.npi,
    persona: userprofile.persona,
    phone: contactInfo.phone,
    practiceName: userprofile.practice,
    province: contactInfo.state,
    specialty: userprofile.specialty,
    subscribe: userprofile.email_test_updates,
    subspecialty: userprofile.subspecialty,
    zipcode: contactInfo.zipcode,
  }
}
