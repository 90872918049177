import React from 'react'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module ... Remove this comment to see the full error message
import Loader from 'react-loader'
import classnames from 'classnames'

import styles from './Spinner.module.scss'

interface IProps {
  /**
   * Should this component load spinner?
   */
  loading: boolean
  className?: string
  children?: React.ReactFragment
}

const Spinner = (props: IProps) => (
  <Loader
    loaded={!props.loading}
    options={{length: 5, radius: 5, width: 2}}
    parentClassName={classnames(styles.spinner, props.className)}
  >
    {props.loading || props.children}
  </Loader>
)

export default Spinner
