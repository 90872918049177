import {TUserNotifications} from 'models/UserNotifications'

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userNotifications' implicitly has an 'a... Remove this comment to see the full error message
function deserialize(userNotifications): TUserNotifications {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'el' implicitly has an 'any' type.
  return userNotifications.map(el => {
    const {id, notification, resolvable, requisition} = el
    return {
      id,
      reqid: requisition ? requisition.toString() : undefined,
      resolvable,
      type: notification,
    }
  })
}

function serialize(userNotifications: TUserNotifications) {
  return userNotifications.map(el => {
    const {id, resolvable, type, reqid} = el
    return {
      id,
      notification: type,
      requisition: reqid,
      resolvable,
    }
  })
}

export default {
  deserialize,
  serialize,
}
