import forEach from 'lodash/forEach'
import {default as lodashTemplate} from 'lodash/template'
import {IAPIData} from 'models/APIData'
import {IRequisition, OrderTypes} from 'models/Requisition'

import {FEDEX_TRACKING_LINK} from 'api/urls'

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'str' implicitly has an 'any' type.
export function template(str, params) {
  return lodashTemplate(str, {interpolate: /{{([\s\S]+?)}}/g})(params)
}

export function stringifyGenesCount(count: number) {
  let output = `${count} gene`
  if (count !== 1) {
    output += 's'
  }
  return output
}

export const stringifyOrderType = (orderType: OrderTypes) => orderType.toString()

export const replaceContentfulMarkdownWithHtml = (text: string) => {
  return text
    ? text
        .replace(/- (.*)($|\n)/g, '<li>$1</li>')
        .replace(/<li>.*<\/li>/m, '<ul>$&</ul>')
        .replace(/__(.*?)__/g, '<b>$1</b>')
        .replace(/\*(.*?)\*/g, '<i>$1</i>')
        .replace(/\n/g, '<br/>')
    : ''
}

// Capitalize first letter in full name
export const capitalizeName = (name: string) => name.replace(/\b(\w)/g, s => s.toUpperCase())

export const formatExternalUrl = (url: string): string => (url.match('^https?://') ? url : `https://${url}`)

export const swapContentByKeyValue = (text: string, rules: {[name: string]: string}) => {
  // If contentful model does not match our serializer - we could receive undefined as text here.
  // That's why we need a fallback to empty string
  // Otherwise we will see an error on resultString.replace because resultString will be undefined
  let resultString = text || ''
  forEach(rules, (value: string, key: string) => {
    const regex = new RegExp(`{{\\s?${key}\\s?}}`, 'g')
    resultString = resultString.replace(regex, value)
  })

  return resultString
}

export const replaceLastCommaWithAnd = (text: string) => text.replace(/,(?=[^,]*$)/, ' and')

export const formatPpvOrNpv = (ppvOrNpv: string) => (ppvOrNpv === '0' || !ppvOrNpv ? '—' : `${ppvOrNpv}%`)

export const removePredictedFetalSexFromString = (text: string) =>
  text.replace(/Predicted (fetal )?sex is (fe)?male.?/, '').trim()

export const removeSexChromosomesFromString = (text: string) => text.replace(/\(X[XY]\).?/, '').trim()

export const getTrackingLink = (req: IRequisition, apiData: IAPIData) => {
  const {trackNumber, deliveryService} = req

  if (!trackNumber) {
    return
  }

  const deliveryServices = apiData.PORTAL.delivery_services
  return deliveryService && deliveryServices
    ? deliveryServices[deliveryService].replace('{tracking_number}', trackNumber)
    : `${FEDEX_TRACKING_LINK}${trackNumber}`
}

export const ensureSpaceInName = (name: string) => {
  if (name.includes(' ')) return name
  else return `${name} `
}
