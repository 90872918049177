import uniq from 'lodash/uniq'
import without from 'lodash/without'
import {IRequisition} from 'models/Requisition'
import {hasOopEstimateReceipt} from 'services/RequisitionUtils'

const key = 'viewedAttachments'
let viewedAttachments = JSON.parse(localStorage.getItem(key)) || []

export function setViewedAttachmentsIDs(req: IRequisition) {
  const attachmentsIds = req.attachments.map(file => file.id.toString())
  if (hasOopEstimateReceipt(req)) {
    attachmentsIds.push(`${req.reqid}_oop_estimate`)
  }
  viewedAttachments.push(...attachmentsIds)
  viewedAttachments = uniq(viewedAttachments.sort())

  localStorage.setItem(key, JSON.stringify(viewedAttachments))
}

export function getUnreadAttachmentsCount(req: IRequisition) {
  const attachmentsIds = req.attachments.map(file => file.id.toString())
  if (hasOopEstimateReceipt(req)) {
    attachmentsIds.push(`${req.reqid}_oop_estimate`)
  }
  return without(attachmentsIds, ...viewedAttachments).length
}
