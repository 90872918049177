// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'hump... Remove this comment to see the full error message
import {camelizeKeys, decamelizeKeys} from 'humps'
import omit from 'lodash/omit'
import {IPatientConsentPreferences, IPatientsData} from 'models/PatientPreferences'

import {PatientDto} from '@invitae/stargate'

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const deserializePreferences = (data): Partial<IPatientConsentPreferences> => {
  return omit<IPatientConsentPreferences>(camelizeKeys(data), 'id')
}

export const deserializeListNew = (data: PatientDto[]): IPatientsData => {
  const result: IPatientsData = {}

  for (const record of data) {
    const patient = camelizeKeys(record)
    result[patient.reqrepoId] = {
      country: patient.contactInfo.country,
      name: `${patient.firstName} ${patient.lastName}`,
      preferences: deserializePreferences(patient.preferences),
      state: patient.contactInfo.state,
    }
  }

  return result
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const deserializeList = data => {
  const result: IPatientsData = {}

  for (const record of data) {
    const patient = camelizeKeys(record)
    const {id, ...patientData} = patient

    if (patientData.preferences) {
      patientData.preferences = deserializePreferences(patientData.preferences)
    }

    result[id] = patientData
  }

  return result
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const serialize = data => decamelizeKeys(data)
