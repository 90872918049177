import {addNotification} from 'ducks/notifications'
import {getRequisitionsAction} from 'ducks/requisitions'
import {getUserNotifications} from 'ducks/userNotifications'
import {asyncReducer} from 'models/AsyncModel'
import {ITestRegistrationStatus} from 'models/TestRegistration'
import {parse as parseUrlParams} from 'qs'
import {Dispatch} from 'redux'
import {noticeError} from 'services/NoticeError'
import * as TestRegistrationTransformer from 'transformers/TestRegistrationTransformer'

import {errorCodes, getRequest, postRequest} from 'api'
import {API_REGISTER_TEST} from 'api/urls'
import {IFormData} from 'pages/register/RegisterTestForm'
import {AppDispatch} from 'store/configureStore'

const humps: any = require('humps')

const INITIALIZE_TEST_REGISTRATION = 'pe-app/current-user/INITIALIZE_TEST_REGISTRATION'
export const TEST_REGISTRATION_INITIALIZED = 'pe-app/current-user/TEST_REGISTRATION_INITIALIZED'
const REGISTER_TEST = 'pe-app/current-user/REGISTER_TEST'
const TEST_REGISTRATION_COMPLETE = 'pe-app/current-user/TEST_REGISTRATION_COMPLETE'
export const TEST_REGISTRATION_FAILED = 'pe-app/current-user/TEST_REGISTRATION_FAILED'

export const actionTypes = {
  INITIALIZE_TEST_REGISTRATION,
  REGISTER_TEST,
  TEST_REGISTRATION_COMPLETE,
  TEST_REGISTRATION_FAILED,
  TEST_REGISTRATION_INITIALIZED,
}

export const registrationReducer = asyncReducer<ITestRegistrationStatus>({
  completeStates: [TEST_REGISTRATION_COMPLETE, TEST_REGISTRATION_FAILED, TEST_REGISTRATION_INITIALIZED],
  loadingStates: [REGISTER_TEST, INITIALIZE_TEST_REGISTRATION],
})

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
export const initTestRegistration = () => dispatch => {
  dispatch({type: INITIALIZE_TEST_REGISTRATION})
  // Check if user came from link in email
  const params: {token?: string} = parseUrlParams(window.location.search, {ignoreQueryPrefix: true})
  // We are using substr() here to make sure that unexpected characters are not in use for the token.
  // It may happen when user is copying URL incorrectly and token parse does not work as expected.
  // For example: */register-test/step-2?token=ABCDEFGH?source=sms
  const token = params.token !== undefined ? params.token.substr(0, 8) : null
  let url = API_REGISTER_TEST
  if (token) {
    url += `?token=${token}`
  }

  return getRequest(url)
    .then(response => {
      const parsedData = humps.camelizeKeys(response)
      if (token) {
        parsedData.token = token
      }
      dispatch({payload: parsedData, type: TEST_REGISTRATION_INITIALIZED})
    })
    .catch(error => {
      noticeError(error)
      dispatch({type: TEST_REGISTRATION_INITIALIZED})
      throw new Error('Server error')
    })
}

export const registerTest =
  (data: Partial<IFormData>, isPeTestRegistrationErrorsUpdateEnabled: boolean) =>
  async (dispatch: Dispatch<AppDispatch>) => {
    const reqData = TestRegistrationTransformer.serialize(data as IFormData)

    try {
      const response = await postRequest(API_REGISTER_TEST, humps.decamelizeKeys(reqData))
      const parsedData = humps.camelizeKeys(response) as ITestRegistrationStatus

      // saving token after registration attempt
      if (data.token) {
        parsedData.token = data.token
      }

      if (parsedData.awaitingSample) {
        dispatch({payload: parsedData, type: TEST_REGISTRATION_FAILED})

        return {
          data: parsedData,
          ok: false,
        }
      } else {
        dispatch({payload: parsedData, type: TEST_REGISTRATION_COMPLETE})
        dispatch(getRequisitionsAction({reqid: parsedData.requisitionId.toString()}))
        dispatch(getUserNotifications())
        dispatch(addNotification('Test successfully registered.'))

        return {
          data: parsedData,
          ok: true,
        }
      }
    } catch (error) {
      if (error.status !== errorCodes.badRequest) {
        dispatch(addNotification('Server error was detected. Please try again.'))
        throw new Error('Server error')
      }
      let result: ITestRegistrationStatus
      if (isPeTestRegistrationErrorsUpdateEnabled) {
        result = TestRegistrationTransformer.deserializeNewError(humps.camelizeKeys(error.body), data.codeType)
      } else {
        result = TestRegistrationTransformer.deserializeError(humps.camelizeKeys(error.body.error))
      }

      // saving token after registration attempt
      if (data.token) {
        result.token = data.token
      }

      dispatch({payload: result, type: TEST_REGISTRATION_FAILED})
      dispatch(addNotification(result.error))
      throw result.error ? result.error : null
    }
  }
