import {EntryFields} from 'contentful'

export enum ContentTypes {
  mediaWithContent = 'mediaWithContent',
  statusPage = 'statusPage',
  tileItem = 'tileItem',
  // TODO: fix type name in contentful
  // eslint-disable-next-line spellcheck/spell-checker
  tilesWithIcons = 'tilesWIthIcons',
  pageCopy = 'pageCopy',
  componentCopy = 'componentCopy',
  componentBlock = 'componentBlock',
  media = 'media',
  mediaContent = 'mediaContent',
}

export enum NIPSResultsPageModalTypes {
  downloadWarningModal = 'downloadWarningModal',
  predictedSexModal = 'predictedSexModal',
  congratsModal = 'congratsModal',
  shareVideoModal = 'shareVideoModal',
  shareWithOthersModal = 'shareWithOthersModal',
}

export enum NIPSResultsPageTextComponentsTypes {
  introPositive = 'introPositive',
  introNegative = 'introNegative',
  introNegativePartialResult = 'introNegativePartialResult',
  positiveBanner = 'positiveBanner',
  downloadFullReport = 'downloadFullReport',
  beforeRevealSex = 'beforeRevealSex',
  afterRevealSex = 'afterRevealSex',
  secretEmailTemplate = 'secretEmailTemplate',
  notSecretEmailTemplate = 'notSecretEmailTemplate',
  prenatalDiagnosticTestingCard = 'prenatalDiagnosticTestingCard',
  conditionDetails = 'conditionDetails',
  geneticCounselorCard = 'geneticCounselorCard',
  shareWithDoctorCard = 'shareWithDoctorCard',
  nextSteps = 'nextSteps',
  summaryPositive = 'summaryPositive',
  summaryNegative = 'summaryNegative',
  summaryNegativePartialResult = 'summaryNegativePartialResult',
  resultsTable = 'resultsTable',
}

export const NIPSResultsPageComponentTypes = {...NIPSResultsPageTextComponentsTypes, ...NIPSResultsPageModalTypes}
export type TNIPSResultsPageComponentTypes = typeof NIPSResultsPageComponentTypes

export type ContentfulState = {
  nipsResultsPage?: TEntries
}

export type TEntry = TMediaWithContent | TTileItem | TTilesWithIcon | TComponentCopy | TResultSummaryPage
export type TEntries = TEntry[]

interface IEntryCommons {
  id: string
}

export interface IComponentCopy {
  content: ITextCopy
  type: string
}

export interface ITextCopy {
  [fieldName: string]: any
}

export type TComponentCopy = IComponentCopy & IEntryCommons & {contentType: ContentTypes.componentCopy}

export interface IResultSummaryPageFields {
  components: {
    [componentName: string]: TComponentCopy
  }
}

export type TResultSummaryPage = IResultSummaryPageFields & IEntryCommons & {contentType: ContentTypes.pageCopy}

export interface ITilesWithIconFields {
  header: EntryFields.Symbol
  subheader: EntryFields.Symbol
  tile: TTileItem[]
}

export type TTilesWithIcon = ITilesWithIconFields & IEntryCommons & {contentType: ContentTypes.tilesWithIcons}

export interface IMediaWithContentFields {
  title: EntryFields.Symbol
  content: EntryFields.RichText
  mediaPlacement: EntryFields.Symbol
  mediaBorder: EntryFields.Boolean
  media: IMedia | null
  video: any
}

export type TMediaWithContent = IMediaWithContentFields & IEntryCommons & {contentType: ContentTypes.mediaWithContent}

export interface ITileItemFields {
  title: EntryFields.Symbol
  content: EntryFields.RichText
  icon: EntryFields.Symbol
}

export type TTileItem = ITileItemFields & Partial<IEntryCommons> & {contentType?: ContentTypes.tileItem}

export interface IMedia {
  title: string
  description: string
  fileUrl: string
  fileContentType: string
  details: {
    size: number
    image?: {
      width: number
      height: number
    }
  }
}
