import {addNotification} from 'ducks/notifications'
import {IGCAppointmentFormDataComplete, IGCAppointmentResponse, IGeneticCounselingState} from 'models/GeneticCounseling'
import {Dispatch} from 'redux'
import {createAction, handleActions} from 'redux-actions'
import {noticeError} from 'services/NoticeError'
import {deserializeAppointment} from 'transformers/GeneticCounselingTransformer'

import {
  deleteScheduledGeneticCounselingAppointment,
  getScheduledGeneticCounselingAppointment,
  postScheduleGeneticCounselingAppointment,
  updateScheduledGeneticCounselingAppointment,
} from 'api/geneticCounseling'

const SCHEDULE_GENETIC_COUNSELING_START = 'pe-app/SCHEDULE_GENETIC_COUNSELING/START'
const SCHEDULE_GENETIC_COUNSELING_DONE = 'pe-app/SCHEDULE_GENETIC_COUNSELING/DONE'
const SCHEDULE_GENETIC_COUNSELING_FAILED = 'pe-app/SCHEDULE_GENETIC_COUNSELING/FAILED'
const SCHEDULE_GENETIC_COUNSELING_CALL_US_CLICKED = 'pe-app/SCHEDULE_GENETIC_COUNSELING/CALL_US_CLICKED'
const SCHEDULE_GENETIC_COUNSELING_SCHEDULE_APPOINTMENT_CLICKED =
  'pe-app/SCHEDULE_GENETIC_COUNSELING/SCHEDULE_APPOINTMENT_CLICKED'
const SCHEDULE_GENETIC_COUNSELING_CONTINUE_CLICKED = 'pe-app/SCHEDULE_GENETIC_COUNSELING/CONTINUE_CLICKED' // Availability page

const GET_APPOINTMENT_START = 'pe-app/GET_APPOINTMENT/START'
const GET_APPOINTMENT_DONE = 'pe-app/GET_APPOINTMENT/DONE'
const GET_APPOINTMENT_FAILED = 'pe-app/GET_APPOINTMENT/FAILED'

const DELETE_GENETIC_COUNSELING_APPOINTMENT_START = 'pe-app/DELETE_GENETIC_COUNSELING_APPOINTMENT/START'
const DELETE_GENETIC_COUNSELING_APPOINTMENT_DONE = 'pe-app/DELETE_GENETIC_COUNSELING_APPOINTMENT/DONE'
const DELETE_GENETIC_COUNSELING_APPOINTMENT_FAILED = 'pe-app/DELETE_GENETIC_COUNSELING_APPOINTMENT/FAILED'

const UPDATE_GENETIC_COUNSELING_APPOINTMENT_START = 'pe-app/UPDATE_GENETIC_COUNSELING_APPOINTMENT/START'
const UPDATE_GENETIC_COUNSELING_APPOINTMENT_DONE = 'pe-app/UPDATE_GENETIC_COUNSELING_APPOINTMENT/DONE'
const UPDATE_GENETIC_COUNSELING_APPOINTMENT_FAILED = 'pe-app/UPDATE_GENETIC_COUNSELING_APPOINTMENT/FAILED'

// 404 errors are expected if an appointment hasn't been made
const GET_APPOINTMENT_EXPECTED_ERRORS = new Set(['404'])

export const actionTypes = {
  DELETE_GENETIC_COUNSELING_APPOINTMENT_DONE,
  DELETE_GENETIC_COUNSELING_APPOINTMENT_FAILED,
  DELETE_GENETIC_COUNSELING_APPOINTMENT_START,
  GET_APPOINTMENT_DONE,
  GET_APPOINTMENT_FAILED,
  GET_APPOINTMENT_START,
  SCHEDULE_GENETIC_COUNSELING_CALL_US_CLICKED,
  SCHEDULE_GENETIC_COUNSELING_CONTINUE_CLICKED,
  SCHEDULE_GENETIC_COUNSELING_DONE,
  SCHEDULE_GENETIC_COUNSELING_FAILED,
  SCHEDULE_GENETIC_COUNSELING_SCHEDULE_APPOINTMENT_CLICKED,
  SCHEDULE_GENETIC_COUNSELING_START,
  UPDATE_GENETIC_COUNSELING_APPOINTMENT_DONE,
  UPDATE_GENETIC_COUNSELING_APPOINTMENT_FAILED,
  UPDATE_GENETIC_COUNSELING_APPOINTMENT_START,
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const noOpAction = state => state

const initialState: IGeneticCounselingState = {
  calendarId: '-1',
  datetime: '',
  loading: false,
  phone: '',
  timezone: '',
}

export default handleActions<IGeneticCounselingState>(
  {
    [DELETE_GENETIC_COUNSELING_APPOINTMENT_DONE]: () => initialState,
    [DELETE_GENETIC_COUNSELING_APPOINTMENT_FAILED]: state => ({
      ...state,
      loading: false,
    }),
    [DELETE_GENETIC_COUNSELING_APPOINTMENT_START]: state => ({
      ...state,
      loading: true,
    }),
    [GET_APPOINTMENT_DONE]: (state, action) => ({
      calendarId: action.payload.calendarId,
      datetime: action.payload.datetime,
      loading: false,
      phone: action.payload.phone,
      timezone: action.payload.timezone,
    }),
    [GET_APPOINTMENT_FAILED]: () => initialState,
    [GET_APPOINTMENT_START]: () => ({
      ...initialState,
      loading: true,
    }),
    [SCHEDULE_GENETIC_COUNSELING_CALL_US_CLICKED]: noOpAction,
    [SCHEDULE_GENETIC_COUNSELING_CONTINUE_CLICKED]: noOpAction,
    [SCHEDULE_GENETIC_COUNSELING_DONE]: state => ({
      ...state,
      loading: false,
    }),
    [SCHEDULE_GENETIC_COUNSELING_FAILED]: state => ({
      ...state,
      loading: false,
    }),
    [SCHEDULE_GENETIC_COUNSELING_SCHEDULE_APPOINTMENT_CLICKED]: noOpAction,
    [SCHEDULE_GENETIC_COUNSELING_START]: state => ({
      ...state,
      loading: true,
    }),
    [UPDATE_GENETIC_COUNSELING_APPOINTMENT_DONE]: state => ({
      ...state,
      loading: false,
    }),
    [UPDATE_GENETIC_COUNSELING_APPOINTMENT_FAILED]: state => ({
      ...state,
      loading: false,
    }),
    [UPDATE_GENETIC_COUNSELING_APPOINTMENT_START]: state => ({
      ...state,
      loading: true,
    }),
  },
  initialState,
)

const scheduleAppointmentStartAction = createAction(SCHEDULE_GENETIC_COUNSELING_START)
const scheduleAppointmentDoneAction = createAction(SCHEDULE_GENETIC_COUNSELING_DONE)
const scheduleAppointmentFailedAction = createAction(SCHEDULE_GENETIC_COUNSELING_FAILED)
export const scheduleAppointmentCallUsClickedAction = createAction(SCHEDULE_GENETIC_COUNSELING_CALL_US_CLICKED)
export const scheduleAppointmentClickedAction = createAction(SCHEDULE_GENETIC_COUNSELING_SCHEDULE_APPOINTMENT_CLICKED)
export const scheduleAppointmentContinueClickedAction = createAction(SCHEDULE_GENETIC_COUNSELING_CONTINUE_CLICKED)

export const getAppointmentStartAction = createAction(GET_APPOINTMENT_START)
export const getAppointmentDoneAction = createAction(GET_APPOINTMENT_DONE)
const getAppointmentFailedAction = createAction(GET_APPOINTMENT_FAILED)

const deleteAppointmentStartAction = createAction(DELETE_GENETIC_COUNSELING_APPOINTMENT_START)
const deleteAppointmentDoneAction = createAction(DELETE_GENETIC_COUNSELING_APPOINTMENT_DONE)
const deleteAppointmentFailedAction = createAction(DELETE_GENETIC_COUNSELING_APPOINTMENT_FAILED)

const updateAppointmentStartAction = createAction(UPDATE_GENETIC_COUNSELING_APPOINTMENT_START)
const updateAppointmentDoneAction = createAction(UPDATE_GENETIC_COUNSELING_APPOINTMENT_DONE)
const updateAppointmentFailedAction = createAction(UPDATE_GENETIC_COUNSELING_APPOINTMENT_FAILED)

export const scheduleAppointmentAction = (formData: IGCAppointmentFormDataComplete) => {
  return async (dispatch: Dispatch) => {
    dispatch(scheduleAppointmentStartAction())

    try {
      await postScheduleGeneticCounselingAppointment(formData)
      dispatch(scheduleAppointmentDoneAction({reqid: formData.reqid}))
      dispatch(addNotification('Appointment scheduled.'))
      return {success: true}
    } catch (e) {
      dispatch(scheduleAppointmentFailedAction())
      dispatch(addNotification('Server error was detected. Please try again.'))
      console.error(e)
      return Promise.reject(e)
    }
  }
}

export const getAppointmentAction = (reqid: string) => async (dispatch: Dispatch) => {
  dispatch(getAppointmentStartAction())
  try {
    const response: IGCAppointmentResponse = await getScheduledGeneticCounselingAppointment(reqid)
    dispatch(getAppointmentDoneAction(deserializeAppointment(response)))
  } catch (e) {
    const errorStatusCode = e.message.split(':')[0]
    if (!GET_APPOINTMENT_EXPECTED_ERRORS.has(errorStatusCode)) {
      noticeError(e)
    }
    dispatch(getAppointmentFailedAction())
  }
}

export const deleteAppointmentAction = (reqid: string, isAdmin?: boolean) => async (dispatch: Dispatch) => {
  dispatch(deleteAppointmentStartAction())

  try {
    await deleteScheduledGeneticCounselingAppointment(reqid, isAdmin)
    dispatch(deleteAppointmentDoneAction({reqid}))
  } catch (e) {
    noticeError(e)
    dispatch(deleteAppointmentFailedAction())
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
export const updateAppointmentAction = (reqid: string, datetime: string) => async dispatch => {
  dispatch(updateAppointmentStartAction())

  try {
    await updateScheduledGeneticCounselingAppointment(reqid, datetime)
    dispatch(updateAppointmentDoneAction({reqid}))
    dispatch(addNotification('Appointment rescheduled.'))
    return {success: true}
  } catch (e) {
    dispatch(updateAppointmentFailedAction())
    dispatch(addNotification('Server error was detected. Please try again.'))
    return Promise.reject(e)
  }
}
