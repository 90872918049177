import {DateTime} from 'luxon'
import {ITestRegistrationStatus} from 'models/TestRegistration'

import {OUTPUT_DATE_FORMAT, SERVER_DATE_FORMAT} from 'constants/constants'
import {IFormData} from 'pages/register/RegisterTestForm'
import {pluralize} from 'shared/helpers'

const EMPTY_STRING = ''

export const serialize = (data: IFormData) => {
  const {codeType, code, dob, relationship, ...rest} = data
  const result = {
    ...rest,
    dob: dob ? DateTime.fromFormat(dob, OUTPUT_DATE_FORMAT).toFormat(SERVER_DATE_FORMAT) : EMPTY_STRING,
    registration_key: code ? codeType + code : EMPTY_STRING, // Server side require ib<num> format
    relationship_to_patient: relationship?.value ?? '',
  }

  return result
}

export const deserializeNewError = (response: Record<string, any>, codeType: string): ITestRegistrationStatus => {
  const result: ITestRegistrationStatus = {}

  const {attemptsLeft, error} = response
  const isRqCodeType = codeType === 'RQ'
  const attemptsError = ` You have ${pluralize(attemptsLeft, 'more attempt', 'more attempts')} before you're locked out.`
  const codeTypeLabel = isRqCodeType ? 'order number' : 'confirmation code'

  if (attemptsLeft === 0) {
    result.registrationLocked = true
    return result
  }

  if (error.registrationLocked) {
    result.registrationLocked = true
    result.error = 'Registration is locked.'
  } else if (error.registrationKey) {
    result.error = `${codeTypeLabel} is incorrect.`
  }

  switch (error.errorCode) {
    case 'invalid_dob_or_code':
      result.error = `Invalid date of birth or ${codeTypeLabel}.`
      break
    case 'invalid_dob':
      result.error = 'Invalid date of birth.'
      break
    case 'already_registered':
      result.isOwner = true
      result.error = null
      break
    case 'already_registered_by_another_user':
      result.isOwner = false
      result.error = 'Test has already been registered by another user.'
      break
    case 'link_expired':
      result.error = 'This registration link has expired. Please contact Client Services for assistance.'
      break
    case 'test_cancelled':
      result.error = 'This test has been cancelled. Please contact Client Services for assistance.'
      break
    default:
      if (!error.registrationLocked && !error.registrationKey) {
        result.error = 'Server error.'
      }
  }

  if (result.error) {
    result.error += attemptsError
  }

  return result
}

export const deserializeError = (error: Record<string, any>): ITestRegistrationStatus => {
  const result: any = {}

  if (error.registrationLocked) {
    result.registrationLocked = true
    result.error = 'Registration is locked'
  } else if (error.registrationKey) {
    result.error = 'Invalid date of birth or confirmation code'
  } else if (error.dob) {
    result.error = error.dob
  } else if (error.errors) {
    result.error = error.errors
  } else if (error.isOwner !== undefined) {
    /*
    We need to output link to dashboard if user already registered current test
    */
    if (error.isOwner) {
      result.error = null
      result.isOwner = error.isOwner
    } else {
      result.error = error.form
    }
  } else {
    result.error = error.form
  }

  return result
}
