import {IRequisition} from 'models/Requisition'
import {noticeError} from 'services/NoticeError'
import RequisitionTransformer from 'transformers/RequisitionTransformer'

import {getRequest, postRequest} from 'api'
import {
  API_GET_DOCUMENTS,
  API_GET_RECEIPTS,
  API_GET_VARIANTS,
  API_MARK_REPORT_RELEASE_AS_VIEWED,
  API_MARK_VIDEO_AS_WATCHED,
  API_POST_DISMISS_GENETIC_COUNSELING,
  API_REQUEST_RESULTS,
  API_REQUISITIONS,
} from 'api/urls'

export function fetchReceipts(reqid: string, patientCountry: string) {
  const {deserializeReceipts} = RequisitionTransformer
  return getRequest(API_GET_RECEIPTS(reqid))
    .then(receipts => deserializeReceipts(receipts, patientCountry))
    .catch(error => {
      noticeError(error)
      throw new Error(error.stack || `${error.status}: ${error.statusText}`)
    })
}

export function fetchDocuments(reqid: string) {
  const {deserializeAttachments} = RequisitionTransformer
  return getRequest(API_GET_DOCUMENTS(reqid))
    .then(deserializeAttachments)
    .catch(error => {
      noticeError(error)
      throw new Error(error.stack || `${error.status}: ${error.statusText}`)
    })
}

export function fetchVariants(reqid: string) {
  const {deserializeVariants} = RequisitionTransformer
  return getRequest(API_GET_VARIANTS(reqid))
    .then(deserializeVariants)
    .catch(error => {
      noticeError(error)
      throw new Error(error.stack || `${error.status}: ${error.statusText}`)
    })
}

export function fetchRequisitions(): Promise<IRequisition[]> {
  const {deserialize} = RequisitionTransformer

  return getRequest(API_REQUISITIONS)
    .then(data => {
      const reqs = data.map((req: any) => deserialize(req, data))
      RequisitionTransformer.setVisibility(reqs)

      return reqs
    })
    .catch(error => {
      noticeError(error)
      throw new Error(error.stack || `${error.status}: ${error.statusText}`)
    })
}

export function postGeneticCounselingDismissed(reqid: string, didSchedule: boolean, option: string) {
  return postRequest(API_POST_DISMISS_GENETIC_COUNSELING(reqid), {didSchedule, option})
}

export const markSexRevealVideoAsWatchedRequest = (reqid: string) =>
  postRequest(API_MARK_VIDEO_AS_WATCHED, {req_id: reqid})

export const postRequestResults = (reportId: number) => postRequest(API_REQUEST_RESULTS(reportId))

export const postMarkReportReleaseAsViewed = (reportId: number) =>
  postRequest(API_MARK_REPORT_RELEASE_AS_VIEWED(reportId))
