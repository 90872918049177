// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'hump... Remove this comment to see the full error message
import {camelizeKeys} from 'humps'
import findLast from 'lodash/findLast'
import {
  IResultServiceVariant,
  IResultVariant,
  PreSimplifiedToResultsServiceVariantMap,
  ResultServiceZygosity,
  VariantsSource,
} from 'models/Report'
import {
  IRequisition,
  IRequisitionAttachment,
  IRequisitionReport,
  OrderTypes,
  RequisitionAttachmentCategories,
  TRequisitions,
} from 'models/Requisition'
import {RequisitionStageType} from 'models/RequisitionStageType'
import {getUnreadAttachmentsCount} from 'services/AttachmentsViewsHistory'
import {convertDate, shortFormat} from 'services/DateUtils'
import {isUSTerritories} from 'services/RequisitionUtils'
import {formatVariantName, shouldShowResultsSummary} from 'services/ResultsSummary'
import RequisitionStageTransformer from 'transformers/RequisitionStageTransformer'

import {COUNTRY_CA, COUNTRY_PR, COUNTRY_US} from 'constants/constants'

import ReportTransformer from './ReportTransformer'

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'req' implicitly has an 'any' type.
const getDisplayingReports = req => {
  if (!req.reports.length) {
    return []
  }

  const lastReport = req.reports[req.reports.length - 1]
  const reports = [lastReport]

  if (!lastReport.results_released_to_patient) {
    const previousReleasedReport = [...req.reports].reverse().find(r => r.results_released_to_patient)

    if (previousReleasedReport) {
      reports.unshift(previousReleasedReport)
    }
  }

  return reports
}

const deserialize = (req: any, reqs: any = []) => {
  const attachments: IRequisitionAttachment[] = deserializeAttachments(req.documents)
  const excludeReducedStages = isUSTerritories(req.patient_country)
  let stages = !excludeReducedStages && req.reduced_stages.length ? req.reduced_stages : req.stages
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'stage' implicitly has an 'any' type.
  stages = stages.map(stage => RequisitionStageTransformer.deserialize(stage, stages))
  const activeStage = findLast(stages, s => s.isActivated)
  const activeStageName = activeStage ? activeStage.name : findLast(stages, s => s.startedAt !== null).name
  const estimatedAmount = req.estimated_amount
    ? Number(req.estimated_amount).toFixed(req.estimated_amount % 1 ? 2 : 0)
    : undefined
  const estimatedAmountLowerBound = req.estimated_lower_bound
    ? Number(req.estimated_lower_bound).toFixed(req.estimated_lower_bound % 1 ? 2 : 0)
    : undefined
  const estimatedAmountUpperBound = req.estimated_upper_bound
    ? Number(req.estimated_upper_bound).toFixed(req.estimated_upper_bound % 1 ? 2 : 0)
    : undefined
  const patientSelectedPaymentTypeAt =
    req.patient_selected_payment_type_at && shortFormat(req.patient_selected_payment_type_at)
  const paymentSelectionExpiration = req.payment_selection_expiration && shortFormat(req.payment_selection_expiration)
  // OPTIMIZE:
  // maybe it would be better to move these flags out of req scheme and define them as helper functions
  const isCarrier = req.order_type === OrderTypes.carrier
  const isProactive = req.order_type === OrderTypes.preventive
  const isDiagnostic = req.order_type === OrderTypes.diagnostic
  const isNips = req.order_type === OrderTypes.nips
  const isExome = req.order_type === OrderTypes.exome
  const isPGx = req.order_type === OrderTypes.pgx
  const isCombiPOC = req.order_type === OrderTypes.combiPOC

  const {has_sex_reveal_video_watched} = req
  const reports = req.reports.map((report: IRequisitionReport) =>
    ReportTransformer(report, {has_sex_reveal_video_watched}),
  )
  const displayingReports = getDisplayingReports(req)
  const coreRequisition = reqs?.find((item: any) => item.is_tier_one_billing_report && item.parent_reqid === req.reqid)
  const coreDisplayingReports = coreRequisition ? getDisplayingReports(coreRequisition) : []
  coreDisplayingReports.forEach(report => (report.requisition = coreRequisition))

  const orderingClinicianCountry = req.ordering_clinician_country
  const isPatientAmerican = req.patient_country === COUNTRY_US
  const isOrderingClinicianFromUsaCanadaOrPuertoRico = [COUNTRY_US, COUNTRY_CA, COUNTRY_PR].includes(
    orderingClinicianCountry,
  )

  const requisition: IRequisition = {
    ...req,
    active_stage_name: activeStageName,
    attachments,
    bundledRequisitions: req.bundled_requisitions,
    coreDisplayingReports,
    deliveryService: req.delivery_service,
    displayingReports,
    estimatedAmount,
    estimatedAmountLowerBound,
    estimatedAmountUpperBound,
    hasGCDismiss: req.tags && req.tags.includes('genetic counseling dismissed'),
    hasPatientViewedResults: req.has_patient_viewed_results,
    hasPwnOnHold: req.tags && req.tags.includes('pwn result on hold'),
    isANDPartnership: req.is_and_partnership,
    // eslint-disable-next-line spellcheck/spell-checker
    isBTSPartnership: req.is_bts_partnership,
    isCardiologyCategory: req.is_cardiology_category,
    isCarrier,
    isCombiPOC,
    isDiagnostic,
    isExome,
    isHearingLossPanel: req.is_hearing_loss_panel,
    isHereditaryCancerCategory: req.is_hereditary_cancer_category,
    isNeurologyPanel: req.is_neurology_panel,
    isNips,
    isOOPNotApplicable: !!req?.is_oop_not_applicable,
    isOphthalmologyCategory: req.is_ophthalmology_category,
    isOrderedByGenomeMedical: req.is_ordered_by_genome_medical,
    isOrderingClinicianFromUsaCanadaOrPuertoRico,
    isOutOfCriteria: req.is_out_of_criteria,
    isPatientAmerican,
    isPatientInitiated: req.is_patient_initiated,
    isPGx,
    isPregnancyLossOrderGroup: req.is_pregnancy_loss_order_group,
    isPrenatalChromosomalMicroarrayAnalysis: req.is_prenatal_chromosomal_microarray_analysis,
    isProactive,
    isReducedStages: !!req.reduced_stages.length,
    isSendoutNipsOrder: req.is_sendout_nips_order,
    isSparkPartnership: req.is_spark_partnership,
    orderingClinicianCountry,
    partnershipInfo: req.partnership_info,
    patient_id: req.patient_id.toString(),
    patientIsPartner: req.patient_is_partner,
    patientPaymentNotificationsEnabled: req.patient_payment_notifications_enabled,
    patientPaymentStatus: req.payment_status,
    patientPrice: req.patient_price,
    patientSelectedPaymentTypeAt,
    paymentSelectionExpiration,
    reports,
    reqid: req.reqid.toString(),
    selectedPaymentAt: req.patient_selected_payment_type_at && shortFormat(req.patient_selected_payment_type_at),
    selectedPaymentType: req.payment_type,
    shouldDisplayTrackLink: req.tracking_number && activeStageName === RequisitionStageType.KIT_SHIPPED,
    stages,
    stepsCompleted: req.steps_completed,
    trackNumber: req.tracking_number,
  }

  requisition.hasSummaryResults = shouldShowResultsSummary(requisition)
  requisition.unreadAttachmentsCount = getUnreadAttachmentsCount(requisition)

  if (
    requisition.isReducedStages &&
    requisition.active_stage_name === RequisitionStageType.COMPLETE &&
    !requisition.reports.some(r => r.results_released_to_patient)
  ) {
    requisition.active_stage_name = requisition.stages[Math.max(0, requisition.stages.length - 2)].name
    requisition.isAtReview = true
  }

  return requisition
}

const setVisibility = (reqs: TRequisitions) => {
  const reqGroups: any = {}

  reqs.forEach(req => {
    if (!reqGroups[req.patient_id]) {
      reqGroups[req.patient_id] = []
    }
    reqGroups[req.patient_id].push(req)
  })

  Object.keys(reqGroups).forEach(patientID => {
    const group: TRequisitions = reqGroups[patientID]

    if (group.some(req => !req.is_canceled)) {
      group.forEach(req => {
        req.isVisible = !req.is_canceled && !req.is_tier_one_billing_report
      })
    } else {
      let lastCanceled: IRequisition
      let lastCancelDate = new Date(1990, 0, 1)

      group.forEach(req => {
        const cancelDate = !!req.cancelled_at && new Date(req.cancelled_at)
        if (cancelDate && cancelDate > lastCancelDate) {
          lastCanceled = req
          lastCancelDate = cancelDate
        }
      })

      if (lastCanceled) {
        lastCanceled.isVisible = true
      }
    }
  })
}

const deserializeReceipts = (receipts: any[], patientCountry: string) => {
  return receipts.map(receipt => {
    const {amount, datePaid, creditCardLast4, creditCardBrand, testPrice, discount} = camelizeKeys(receipt)
    return {
      amount,
      cardBrand: creditCardBrand,
      datePaid: convertDate(patientCountry, datePaid),
      discount,
      lastNumbers: creditCardLast4,
      testPrice,
    }
  })
}

const deserializeAttachments = (documents: IRequisitionAttachment[]) => {
  const EXCLUDED_TYPES = [RequisitionAttachmentCategories.SAMPLE_FAILURE_LETTER]
  return documents
    .filter((attachment: IRequisitionAttachment) => !EXCLUDED_TYPES.includes(attachment.category))
    .sort((a: IRequisitionAttachment, b: IRequisitionAttachment) => {
      return new Date(b.date_created).getTime() - new Date(a.date_created).getTime()
    })
}

interface IDeserializeVariants {
  preReportSimplifiedVariants?: IResultServiceVariant[]
  variants?: IResultVariant[]
  resultServiceVariants?: IResultServiceVariant[]
}

const deserializeVariants = (variants?: any[]) => {
  const result: IDeserializeVariants = {}

  if (!variants || !variants.length) {
    return result
  }

  variants.forEach(v => {
    if (v.source === VariantsSource.cropResultsService) {
      const {
        associatedDisorders,
        gene: geneName,
        variant: name,
        zygosity,
        variantClassification: classification,
      } = camelizeKeys(v)
      const items = result.resultServiceVariants || []

      items.push({
        associatedDisorders,
        classification,
        geneName,
        name: formatVariantName(name),
        zygosity,
      })

      result.resultServiceVariants = items
    } else {
      const {
        associatedDisorders,
        classification,
        commercialTestCode,
        commercialTestName,
        displayName,
        geneName,
        hasClinicNotes,
        name,
        zygosity,
      } = camelizeKeys(v)
      const variants = result.variants || []
      const preReportSimplifiedVariants = result.preReportSimplifiedVariants || []

      variants.push({
        associatedDisorders,
        classification,
        commercialTestCode,
        commercialTestName,
        displayName,
        geneName,
        hasClinicNotes,
        name: formatVariantName(name),
        zygosity,
      })

      // convert to format used in resultServiceVariants
      preReportSimplifiedVariants.push({
        associatedDisorders,
        classification,
        geneName,
        name: formatVariantName(name),
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because ... Remove this comment to see the full error message
        zygosity: ResultServiceZygosity[PreSimplifiedToResultsServiceVariantMap[zygosity]],
      })

      result.variants = variants
      result.preReportSimplifiedVariants = preReportSimplifiedVariants
    }
  })

  return result
}

export default {
  deserialize,
  deserializeAttachments,
  deserializeReceipts,
  deserializeVariants,
  setVisibility,
}
