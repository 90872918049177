import find from 'lodash/find'
import {RequisitionStageType} from 'models/RequisitionStageType'
import {shortFormat} from 'services/DateUtils'

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'stage' implicitly has an 'any' type.
function stageHasWarning(stage, stages) {
  switch (stage.name) {
    case RequisitionStageType.APPROVED:
      return (
        stage.started_at === null &&
        find(stages, ({name, started_at}) => name === RequisitionStageType.IN_TRANSIT && started_at !== null)
      )
  }

  return false
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'stage' implicitly has an 'any' type.
function deserialize(stage, stages) {
  const {name, started_at, is_activated} = stage

  return {
    hasWarning: stageHasWarning(stage, stages),
    isActivated: !!is_activated,
    name,
    startedAt: started_at && shortFormat(started_at),
  }
}

export default {
  deserialize,
  stageHasWarning,
}
