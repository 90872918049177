import {addNotification} from 'ducks/notifications'
import {IAsyncPartnerLink, IPartnerLink} from 'models/PartnerLink'
import {Action, createAction, handleActions} from 'redux-actions'
import {noticeError} from 'services/NoticeError'

import {fetchPartnerLink, patchPartnerLink, postPartnerLink} from 'api/partnerLink'
import {IFormData} from 'components/InvitePartner/InvitePartnerForm'
import {ANALYTICS_QUEUE_SITE_LOCATION_PROPERTY} from 'constants/constants'

const GET_PARTNER_LINK_START = 'API/PARTNER_LINK/GET/START'
const GET_PARTNER_LINK_DONE = 'API/PARTNER_LINK/GET/DONE'
const GET_PARTNER_LINK_FAILED = 'API/PARTNER_LINK/GET/FAILED'
const INVITE_PARTNER_START = 'API/PARTNER_LINK/POST/START'
const INVITE_PARTNER_DONE = 'API/PARTNER_LINK/POST/DONE'
const INVITE_PARTNER_FAILED = 'API/PARTNER_LINK/POST/FAILED'

export const actionTypes = {
  GET_PARTNER_LINK_DONE,
  GET_PARTNER_LINK_FAILED,
  GET_PARTNER_LINK_START,
  INVITE_PARTNER_DONE,
  INVITE_PARTNER_FAILED,
  INVITE_PARTNER_START,
}

export default handleActions<IAsyncPartnerLink, any>(
  {
    [GET_PARTNER_LINK_DONE]: (state: IAsyncPartnerLink, action: Action<IPartnerLink>) => {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    },
    [GET_PARTNER_LINK_FAILED]: (state: IAsyncPartnerLink) => {
      return {
        ...state,
        data: undefined,
        loading: false,
      }
    },
    [GET_PARTNER_LINK_START]: (state: IAsyncPartnerLink) => ({
      ...state,
      data: undefined,
      loading: true,
    }),
    [INVITE_PARTNER_DONE]: (state: IAsyncPartnerLink, action: Action<IPartnerLink>) => {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    },
    [INVITE_PARTNER_FAILED]: (state: IAsyncPartnerLink) => {
      return {
        ...state,
        loading: false,
      }
    },
    [INVITE_PARTNER_START]: (state: IAsyncPartnerLink) => {
      return {
        ...state,
        loading: true,
      }
    },
  },
  {
    data: undefined,
    loading: true,
  },
)

const getPartnerLinkStartAction = createAction(GET_PARTNER_LINK_START)
const getPartnerLinkDoneAction = createAction(GET_PARTNER_LINK_DONE)
const getPartnerLinkFailedAction = createAction(GET_PARTNER_LINK_FAILED)
const invitePartnerStartAction = createAction(INVITE_PARTNER_START)
const invitePartnerDoneAction = createAction(
  INVITE_PARTNER_DONE,
  data => data,
  (data, location) => ({location}),
)
const invitePartnerFailedAction = createAction(INVITE_PARTNER_FAILED)

export const getPartnerLinkAction = (reqId: string) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return async dispatch => {
    dispatch(getPartnerLinkStartAction())

    try {
      const data = await fetchPartnerLink(reqId)
      dispatch(getPartnerLinkDoneAction(data))
    } catch (e) {
      noticeError(e)
      dispatch(getPartnerLinkFailedAction())
      dispatch(addNotification('Server error was detected. Please try again.'))
    }
  }
}

export const invitePartnerAction = (
  reqId: string,
  formData: IFormData,
  location: ANALYTICS_QUEUE_SITE_LOCATION_PROPERTY,
  isUpdate = false,
) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return async dispatch => {
    dispatch(invitePartnerStartAction())

    try {
      const method = isUpdate ? patchPartnerLink : postPartnerLink
      const data = await method(reqId, formData)
      dispatch(invitePartnerDoneAction({reqid: reqId, ...data}, location))
      dispatch(addNotification(`An email was sent to ${formData.firstName} ${formData.lastName}`))
    } catch (e) {
      noticeError(e)
      dispatch(invitePartnerFailedAction())
      dispatch(addNotification('Server error was detected. Please try again.'))
    }
  }
}

export const reinvitePartnerAction = (
  reqId: string,
  formData: IFormData,
  location: ANALYTICS_QUEUE_SITE_LOCATION_PROPERTY,
) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => dispatch(invitePartnerAction(reqId, formData, location, true))
}
