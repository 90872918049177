import {Configuration, FeatureFlagsApi, PatientsApi} from '@invitae/stargate'

import {STARGATE_URL} from './invitaeUrl'

const configuration: Configuration = new Configuration({basePath: STARGATE_URL})

const featureFlags = new FeatureFlagsApi(configuration)
const patients = new PatientsApi(configuration)

export const stargate = Object.freeze({featureFlags, patients})
