import {deserialize, serialize} from 'transformers/PartnerLinkTransformer'

import {errorCodes, getRequest, patchRequest, postRequest} from 'api'
import {API_PARTNER} from 'api/urls'

export const postPartnerLink = async (reqId: string, formData: any) => {
  let data
  try {
    data = await postRequest(API_PARTNER(reqId), serialize(formData))
  } catch (e) {
    throw new Error(e.stack || `${e.status}: ${e.statusText}`)
  }
  return deserialize(data)
}

export const patchPartnerLink = async (reqId: string, formData: any) => {
  let data
  try {
    data = await patchRequest(API_PARTNER(reqId), serialize(formData))
  } catch (e) {
    throw new Error(e.stack || `${e.status}: ${e.statusText}`)
  }
  return deserialize(data)
}

export const fetchPartnerLink = async (reqId: string) => {
  let data
  try {
    data = await getRequest(API_PARTNER(reqId))
  } catch (e) {
    if (e.status !== errorCodes.notFound) {
      throw new Error(e.stack || `${e.status}: ${e.statusText}`)
    }
    return undefined
  }
  return deserialize(data)
}
