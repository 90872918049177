import {getRequisitionsAction} from 'ducks/requisitions'
import {asyncReducer} from 'models/AsyncModel'
import {IRequestResultsResponse} from 'models/RequestResults'
import {IRequisition, IRequisitionReport} from 'models/Requisition'
import {Dispatch} from 'redux'
import {createAction} from 'redux-actions'
import {noticeError} from 'services/NoticeError'

import {IExecuteResponse} from 'api'
import {postRequestResults} from 'api/requisitions'
import {AppDispatch} from 'store/configureStore'

const REQUEST_RESULTS = 'pe-app/results/REQUEST_RESULTS'
const REQUEST_RESULTS_COMPLETE = 'pe-app/results/REQUEST_RESULTS_COMPLETE'
const REQUEST_RESULTS_ERROR = 'pe-app/results/REQUEST_RESULTS_ERROR'

export const actionTypes = {
  REQUEST_RESULTS,
  REQUEST_RESULTS_COMPLETE,
  REQUEST_RESULTS_ERROR,
}

const requestResultsAction = createAction(REQUEST_RESULTS)
const requestResultsCompleteAction = createAction(REQUEST_RESULTS_COMPLETE)
const requestResultsErrorAction = createAction(REQUEST_RESULTS_ERROR)

export default asyncReducer<IRequestResultsResponse>(
  {
    completeStates: [REQUEST_RESULTS_COMPLETE, REQUEST_RESULTS_ERROR],
    loadingStates: [REQUEST_RESULTS],
  },
  false,
)

/**
 * Change requisition's report status to requested by patient
 */
export const requestResults =
  (requisition: IRequisition, report: IRequisitionReport) => (dispatch: Dispatch<AppDispatch>) => {
    dispatch(requestResultsAction({reqid: requisition.reqid}))
    return postRequestResults(report.id)
      .then((responseData: IRequestResultsResponse) => {
        dispatch(getRequisitionsAction({reqid: requisition.reqid}))
        dispatch(requestResultsCompleteAction(responseData))
      })
      .catch((response: IExecuteResponse) => {
        noticeError(response)
        const {status, statusText} = response
        dispatch(requestResultsErrorAction({status, statusText}))
      })
  }
