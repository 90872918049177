// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'WrappedComponent' implicitly has an 'an... Remove this comment to see the full error message
export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export function replaceUnderscoresWithSpaces(str: string) {
  return str.replace(/_/g, ' ')
}

export const formatUSD = (() => {
  const format = new Intl.NumberFormat('en-US', {currency: 'USD', style: 'currency'}).format
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'amount' implicitly has an 'any' type.
  return amount => format(amount).replace(/\D00$/, '')
})()

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'firstDate' implicitly has an 'any' type... Remove this comment to see the full error message
const daysBetween = (firstDate, secondDate) => Math.floor(Math.abs(firstDate - secondDate) / (1000 * 60 * 60 * 24))

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
export const daysSince = date => daysBetween(date, Date.now())

export const pluralize = (value: number, singular: string, plural: string) =>
  value === 1 ? `${value} ${singular}` : `${value} ${plural}`

export const formatDaysSince = (date: string) => pluralize(daysSince(Date.parse(date)), 'day', 'days')

const secondsPerMinute = 60
const secondsPerHour = 60 * secondsPerMinute
const secondsPerDay = 24 * secondsPerHour
const secondsPerYear = 365 * secondsPerDay

export const durationForHumans = (seconds: number) => {
  seconds = Math.round(seconds)
  const levels: Array<[number, string]> = [
    [Math.floor(seconds / secondsPerYear), 'years'],
    [Math.floor((seconds % secondsPerYear) / secondsPerDay), 'days'],
    [Math.floor(((seconds % secondsPerYear) % secondsPerDay) / secondsPerHour), 'hours'],
    [Math.floor((((seconds % secondsPerYear) % secondsPerDay) % secondsPerHour) / 60), 'minutes'],
    [(((seconds % secondsPerYear) % secondsPerDay) % secondsPerHour) % secondsPerMinute, 'seconds'],
  ]
  let returnText = ''

  for (const [value, word] of levels) {
    if (value === 0) {
      continue
    }
    returnText += ` ${value} ${value === 1 ? word.substr(0, word.length - 1) : word}`
  }
  return returnText.trim()
}
