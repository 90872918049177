declare const process: {
  env: {
    NODE_ENV: string
  }
}

window.ORDER_STAGE_NAMES = {}

let store
if (process.env.NODE_ENV === 'production') {
  store = require('./configureStore.prod').default
} else {
  store = require('./configureStore.dev').default
}

// Infer types for dispatch hook as it's recommended in official
// docs https://redux.js.org/usage/usage-with-typescript
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof store>
export type AppDispatch = typeof store.dispatch

export default store as AppStore
