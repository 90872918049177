import {IAsyncModel} from 'models/AsyncModel'
import {ContentfulState} from 'models/Contentful'
import {Action, createAction, handleActions} from 'redux-actions'
import {fetchRemoteContent} from 'services/ContentfulService'
import {deserializeEntries} from 'transformers/ContentfulTransformer'

export const GET_REMOTE_CONTENT_START = 'pe-app/contentful/GET_REMOTE_CONTENT_START'
export const GET_REMOTE_CONTENT_DONE = 'pe-app/contentful/GET_REMOTE_CONTENT_DONE'
export const GET_REMOTE_CONTENT_FAILED = 'pe-app/contentful/GET_REMOTE_CONTENT_FAILED'

export default handleActions<IAsyncModel<ContentfulState>, any>(
  {
    [GET_REMOTE_CONTENT_DONE]: (state, action) => ({
      data: {
        ...state.data,
        [action.payload.pageName]: action.payload.data,
      },
      loading: false,
    }),
    [GET_REMOTE_CONTENT_FAILED]: state => ({
      ...state,
      loading: false,
    }),
    [GET_REMOTE_CONTENT_START]: state => ({
      ...state,
      loading: true,
    }),
  },
  {data: {}, loading: true},
)

const getRemoteContentStart = createAction(GET_REMOTE_CONTENT_START)
const getRemoteContentDone = createAction(GET_REMOTE_CONTENT_DONE)
const getRemoteContentFailed = createAction(GET_REMOTE_CONTENT_FAILED)

export const getRemoteContent =
  (props: Record<string, string>, pageName: string) => async (dispatch: (action: Action<any>) => void) => {
    dispatch(getRemoteContentStart())

    try {
      const entries = await fetchRemoteContent(props)
      dispatch(
        getRemoteContentDone({
          data: deserializeEntries(entries),
          pageName,
        }),
      )
    } catch {
      dispatch(getRemoteContentFailed())
    }
  }
