import includes from 'lodash/includes'
import {Action} from 'redux-actions'

export interface IAsyncModel<T> {
  loading: boolean
  data: T
}

export function asyncReducer<T>(args: {loadingStates: string[]; completeStates: string[]}, defaultLoading = true) {
  function reducer(state: IAsyncModel<T> = {data: {} as T, loading: defaultLoading}, action: Action<any>) {
    if (includes(args.loadingStates, action.type)) {
      return {
        data: state.data,
        loading: true,
      }
    } else if (includes(args.completeStates, action.type)) {
      return {
        data: action.payload,
        loading: false,
      }
    } else {
      return state
    }
  }

  return reducer
}
