import get from 'lodash/get'
import last from 'lodash/last'
import {PaymentStatus, PaymentTypes} from 'models/Payment'
import {
  IRequisition,
  IRequisitionConcept,
  IRequisitionReport,
  KitTypes,
  OrderTypes,
  PeTag,
  ReportLevelStatuses,
  RequisitionConceptCategoryType,
} from 'models/Requisition'
import {RequisitionContentKeys, RequisitionStageType} from 'models/RequisitionStageType'

import {nipsSendOutAddonsWithSexChromosomesProductCodes} from 'constants/concepts'
import {daysSince} from 'shared/helpers'

import {stringifyGenesCount} from './StringUtils'

export function getTestMainCategoryCode(req: IRequisition) {
  const conceptsMainCategories = req.concepts
    .map(concept =>
      (concept.categories || []).filter(
        category => category.concept_type === RequisitionConceptCategoryType.main_category,
      ),
    )
    .filter(i => i.length)
  // if we found only one concept category w/ main_category, return the code
  if (conceptsMainCategories.length === 1 && conceptsMainCategories[0].length === 1) {
    return conceptsMainCategories[0][0].code
  }
  return null
}

export const hasOopEstimateReceipt = (req: IRequisition) =>
  !!(
    (req.estimatedAmount || (req.estimatedAmountLowerBound && req.estimatedAmountUpperBound)) &&
    req.patientSelectedPaymentTypeAt &&
    req.paymentSelectionExpiration
  )

export function getLastReport(req: IRequisition) {
  return last(req.reports)
}

export function getReportScheduledReleaseDate(req: IRequisition) {
  const lastReport = getLastReport(req)
  return lastReport && lastReport.report_release ? lastReport.report_release.scheduled_release_date : null
}

export function getReportReleaseToClinicianDate(req: IRequisition) {
  const lastReport = getLastReport(req)
  return lastReport ? Date.parse(lastReport.date_created) : null
}

export function getIsReportReleased(req: IRequisition) {
  const lastReport = getLastReport(req)
  return lastReport && lastReport.results_released_to_patient
}

export function getNipsReportPrimaryFindingsResult(
  req: IRequisition,
  report?: IRequisitionReport,
): ReportLevelStatuses | null {
  const lastReport = report === undefined ? getLastReport(req) : report

  if (!req.isNips) {
    return null
  }

  return req.report_status !== null
    ? req.report_status
    : get(lastReport, 'report_data.inhouseNips.primaryFindings.reportResult', null)
}

export function getStageCaption(req: IRequisition) {
  switch (req.active_stage_name) {
    case RequisitionStageType.KIT_PURCHASED:
      return 'Kit purchased'
    case RequisitionStageType.KIT_SHIPPED:
      return 'Kit shipped'

    case RequisitionStageType.APPROVED:
      return 'Order approved'
    case RequisitionStageType.SUBMITTED:
      return 'Order submitted'

    case RequisitionStageType.IN_TRANSIT:
      return 'Sample received'

    case RequisitionStageType.PROCESSING:
      return 'Lab processing'

    case RequisitionStageType.ANALYSIS:
      return 'Analysis and interpretation'

    case RequisitionStageType.COMPLETE:
      if (!getIsReportReleased(req)) {
        return 'Clinician review'
      }
      return 'Report released'
  }
}

export function shouldShowPaymentPatientPay(reqs: IRequisition[]) {
  // payment type = patient AND
  // payment status = pending AND
  // RQ does not have reports released in backoffice
  return reqs.some(
    req =>
      req.selectedPaymentType === PaymentTypes.Patient &&
      req.patientPaymentStatus === PaymentStatus.pending &&
      !req.has_reports_released,
  )
}

export function shouldShowPaymentInsurance(reqs: IRequisition[]) {
  // payment type = insurance
  // [OR]
  // payment method = patient AND
  // payment status = pending AND
  // RQ has reports released in backoffice
  return reqs.some(
    req =>
      req.selectedPaymentType === PaymentTypes.Insurance ||
      (req.selectedPaymentType === PaymentTypes.Patient &&
        req.patientPaymentStatus === PaymentStatus.pending &&
        req.has_reports_released),
  )
}

export const isUnpaidStatus = (status: PaymentStatus) =>
  status === PaymentStatus.pending || status === PaymentStatus.empty

export const isUnpaidPatientPayReq = (req: IRequisition): boolean =>
  !!(
    req.selectedPaymentType === PaymentTypes.Patient &&
    parseFloat(req.patientPrice) &&
    isUnpaidStatus(req.patientPaymentStatus) &&
    req.patientPaymentNotificationsEnabled
  )

export const isPaidPatientPayReq = (req: IRequisition): boolean =>
  !!(
    req.selectedPaymentType === PaymentTypes.Patient &&
    parseFloat(req.patientPrice) &&
    req.patientPaymentNotificationsEnabled &&
    req.patientPaymentStatus === PaymentStatus.complete
  )

export function getReportStatusFromRequisition(requisition: IRequisition): ReportLevelStatuses {
  const isSimplifiedReport = !!requisition.report_status

  if (isSimplifiedReport) {
    return requisition.report_status
  } else {
    return requisition.result_is_positive ? ReportLevelStatuses.positive : ReportLevelStatuses.negative
  }
}

export const getTestNamesForRequisition = (requisition: IRequisition) =>
  requisition.concepts.map(c => c.concept_name).join(', ')

export const isOopWorkflowEligible = (requisition: IRequisition): boolean =>
  !!(
    requisition &&
    requisition.patientPrice &&
    isUnpaidStatus(requisition.patientPaymentStatus) &&
    (requisition.estimatedAmount ||
      !!requisition.isOOPNotApplicable ||
      (requisition.estimatedAmountUpperBound && requisition.estimatedAmountLowerBound)) &&
    requisition.payment_type === PaymentTypes.Insurance
  )

export const isHolisticBillingFlow = (requisition: IRequisition): boolean => {
  const isInsurancePaymentType = requisition.payment_type === PaymentTypes.Insurance
  const isPatientPriceDefined = !!requisition.patientPrice
  const isReqUnpaid = isUnpaidStatus(requisition.patientPaymentStatus)
  const isHolisticBillingTag = requisition.tags?.some(tag =>
    ([PeTag.HolisticBillingGovernmentPayor, PeTag.NoHolisticBillingGovernmentPayor] as string[]).includes(tag),
  )
  const isOopNotDefined =
    !requisition.estimatedAmount &&
    !requisition.estimatedAmountLowerBound &&
    !requisition.estimatedAmountUpperBound &&
    !requisition.isOOPNotApplicable

  return isHolisticBillingTag && isInsurancePaymentType && isPatientPriceDefined && isReqUnpaid && isOopNotDefined
}

export const isLowOopWorkflowEligible = (requisition: IRequisition): boolean =>
  isOopWorkflowEligible(requisition) && Number(requisition.patientPrice) > Number(requisition.estimatedAmount)

export const isRequisitionNotReportedOrReportedNotMoreThanOneWeek = (req: IRequisition) => {
  return !req.has_reports_released || (req.has_reports_released && daysSince(getReportReleaseToClinicianDate(req)) <= 7)
}

export const isFlip2CashWorkflowEligible = (requisition: IRequisition): boolean =>
  !!(
    requisition &&
    parseFloat(requisition.estimatedAmount) &&
    !requisition.has_reports_released &&
    isUnpaidStatus(requisition.patientPaymentStatus) &&
    requisition.selectedPaymentType === PaymentTypes.Insurance &&
    parseInt(requisition.estimatedAmount) > parseInt(requisition.patientPrice)
  )

export const isOutOfCriteriaWorkflowEligible = (requisition: IRequisition): boolean =>
  requisition.isOutOfCriteria && requisition.payment_type === PaymentTypes.Patient

export const getIsNipsAddonsIncluded = (req: IRequisition): boolean => {
  const reqConceptCodes = req.concepts.map(({concept_code}) => concept_code)
  return reqConceptCodes.some(
    code =>
      code && Object.values(nipsSendOutAddonsWithSexChromosomesProductCodes).some(codeRegex => codeRegex.test(code)),
  )
}

export const getRequisitionById = (requisitions: IRequisition[], reqid: string): IRequisition | undefined =>
  requisitions.find(r => r.reqid === reqid)

export const showGeneticCounselingBanner = (requisition: IRequisition) =>
  requisition.hasPwnOnHold && !requisition.hasGCDismiss

// helper - output column classes for 'what's next' based on order type
export const getWhatsNextColumnClasses = ({isCarrier, isNips, isProactive}: IRequisition) => {
  const classes: string[] = ['col-xs-12']

  if (isCarrier || isNips || isProactive) {
    classes.push('col-sm-6')
    classes.push('col-sm-offset-1')
  } else {
    classes.push('col-md-9')
  }

  return classes.join(' ')
}

// Check if the requisition is in any of the stages that happen before lab processing
export const isCollectionStage = (stageName: string) => {
  return [
    RequisitionContentKeys.KitPurchased,
    RequisitionContentKeys.KitShipped,
    RequisitionContentKeys.Approved,
    RequisitionContentKeys.Submitted,
    RequisitionContentKeys.InTransit,
  ].includes(stageName as RequisitionContentKeys)
}

export const getKitType = (orderType: OrderTypes) => (orderType === OrderTypes.pgx ? KitTypes.buccal : KitTypes.saliva)

export const getConceptGeneCountCaption = (concept: IRequisitionConcept) => {
  // Mapper returns "total_genes_count: 0" for RNA add-ons, but we don't want to add "(0 genes)" to the caption
  // (see PS-2930 for the details)
  return concept.total_genes_count ? `(${stringifyGenesCount(concept.total_genes_count)})` : ''
}

export const isUSTerritories = (country: string): boolean =>
  [
    'US',
    'AS', // American Samoa
    'GU', // Guam
    'MP', // Northern Mariana Islands
    'PR', // Puerto Rico
    'VI', // United States Virgin Islands (Virgin Islands, U.S.)
    'UM', // United States Minor Outlying Islands
  ].includes(country)
