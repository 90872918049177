import 'react-app-polyfill/stable'
import 'react-app-polyfill/jsdom'

import {lazy, Suspense} from 'react'
import ReactDOM from 'react-dom'
import {QueryClient, QueryClientProvider} from 'react-query'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'
import history from 'services/historyService'

import {LocationLiteProvider} from '@invitae/location-lite-react'

import store from 'store/configureStore'

import Spinner from './components/Spinner/Spinner'
import {STARGATE_URL} from './services/invitaeUrl'

import 'react-datepicker/dist/react-datepicker.css'
import 'index.css'
import 'sass/headers.module.scss'
import 'sass/wrap-ids-sass.scss'
import 'sass/datepicker-override.scss'

const App = lazy(() => import('containers/AppShell/App'))

/*Fetch query(apiData) only one time, during root component initialization,
 to prevent react-router warnings caused by rerender of React router wrapped
 by QueryClintProvider*/
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <LocationLiteProvider apiGateway={STARGATE_URL}>
    <Provider store={store}>
      <Suspense fallback={<Spinner loading={true} />}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <App />
          </Router>
        </QueryClientProvider>
      </Suspense>
    </Provider>
  </LocationLiteProvider>,
  document.getElementById('root'),
)
