import {addNotification} from 'ducks/notifications'
import {asyncReducer} from 'models/AsyncModel'
import {ICurrentUser} from 'models/CurrentUser'
import {ThunkDispatch} from 'redux-thunk'
import {noticeError} from 'services/NoticeError'
import CurrentUserTransformer from 'transformers/CurrentUserTransformer'

import {getRequest, postRequest} from 'api'
import {API_CONTACT} from 'api/urls'

export const GET_CONTACT = 'pe-app/current-user/GET_CONTACT'
export const GET_CONTACT_COMPLETE = 'pe-app/current-user/GET_CONTACT_COMPLETE'
export const CHANGE_CONTACT = 'pe-app/current-user/CHANGE_CONTACT'
export const CHANGE_CONTACT_COMPLETE = 'pe-app/current-user/CHANGE_CONTACT_COMPLETE'

export default asyncReducer<ICurrentUser>({
  completeStates: [GET_CONTACT_COMPLETE, CHANGE_CONTACT_COMPLETE],
  loadingStates: [GET_CONTACT],
})

export const getContact = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch({type: GET_CONTACT})
  return getRequest(API_CONTACT)
    .then(currentUser => {
      const parsedData = CurrentUserTransformer.deserialize(currentUser)
      dispatch({payload: parsedData, type: GET_CONTACT_COMPLETE})
    })
    .catch(error => {
      noticeError(error)
      dispatch({payload: {}, type: GET_CONTACT_COMPLETE})
      throw new Error('Server error')
    })
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const changeContact = data => dispatch => {
  dispatch({type: CHANGE_CONTACT})
  return new Promise((resolve, reject) => {
    postRequest(API_CONTACT, CurrentUserTransformer.serialize(data))
      .then(currentUser => {
        const parsedData = CurrentUserTransformer.deserialize(currentUser)
        dispatch({payload: parsedData, type: CHANGE_CONTACT_COMPLETE})
        resolve(currentUser)
        dispatch(addNotification('Contact information successfully updated.'))
      })
      .catch(err => {
        noticeError(err)
        if (err.body && err.body.errors) {
          reject(CurrentUserTransformer.deserializeErrors(err.body.errors))
        } else {
          reject()
        }
      })
  })
}
