import {IDownloadReportPayload} from 'analytics/event-producers/downloadReport'
import {addNotification} from 'ducks/notifications'
import {getPartnerLinkAction} from 'ducks/partnerLink'
import {addCustomNotifications} from 'ducks/userNotifications'
import {IRequisitionPayload} from 'models/AnalyticsQueue'
import {IResultServiceVariant, IResultVariant} from 'models/Report'
import {
  IAsyncRequisitions,
  IRequisition,
  IRequisitionAttachment,
  IRequisitionReceipt,
  TRequisitions,
} from 'models/Requisition'
import IStore from 'models/Store'
import {Dispatch} from 'redux'
import {Action, createAction, handleActions} from 'redux-actions'
import {getUnreadAttachmentsCount, setViewedAttachmentsIDs} from 'services/AttachmentsViewsHistory'
import {noticeError} from 'services/NoticeError'
import RequisitionTransformer from 'transformers/RequisitionTransformer'

import {
  fetchDocuments,
  fetchReceipts,
  fetchRequisitions,
  fetchVariants,
  markSexRevealVideoAsWatchedRequest,
  postGeneticCounselingDismissed,
  postMarkReportReleaseAsViewed,
} from 'api/requisitions'
import {COUNTRY_US} from 'constants/constants'
import {AppDispatch} from 'store/configureStore'

const GET_DOCUMENTS_START = 'pe-app/results/GET_DOCUMENTS/START'
const GET_DOCUMENTS_DONE = 'pe-app/results/GET_DOCUMENTS/DONE'
const GET_REQUISITION_START = 'pe-app/results/GET_REQUISITION/START'
export const GET_REQUISITION_DONE = 'pe-app/results/GET_REQUISITION/DONE'
const GET_RECEIPTS_START = 'pe-app/results/GET_RECEIPTS/START'
const GET_RECEIPTS_DONE = 'pe-app/results/GET_RECEIPTS/DONE'
const GET_VARIANTS_START = 'pe-app/results/GET_VARIANTS/START'
const GET_VARIANTS_DONE = 'pe-app/results/GET_VARIANTS/DONE'
const UPDATE_REPORT_IS_DOWNLOADED = 'pe-app/results/UPDATE_REPORT_IS_DOWNLOADED'
const UPDATE_REQUISITION = 'pe-app/results/UPDATE_REQUISITION'
const UPDATE_REQUISITION_RECEIPTS = 'pe-app/results/UPDATE_REQUISITION_RECEIPTS'
const SET_UNREAD_ATTACHMENTS_COUNT = 'pe-app/results/SET_UNREAD_ATTACHMENTS_COUNT'
const REPORT_DOWNLOADED = 'pe-app/results/REPORT_DOWNLOADED'
const INTERACTIVE_RESULTS_TABLE_ROW_TOGGLED = 'pe-app/results/RESULTS_ROW_TOGGLED'
const PROVIDER_SHARE_LINK_COPIED = 'pe-app/results/PROVIDER_SHARE_LINK_COPIED'
const SHARE_COVERSHEET_PRINTED = 'pe-app/results/SHARE_COVERSHEET_PRINTED'
const DISMISS_GC_BANNER = 'pe-app/results/DISMISS_GC_BANNER'
const SCHEDULE_GC_APPOINTMENT = 'pe-app/results/SCHEDULE_GC_APPOINTMENT'
const TALK_TO_GC_CLICKED_STATUS = 'pe-app/results/TALK_TO_GC_CLICKED_FROM_STATUS'
const SCHEDULE_APPOINTMENT_CLICKED_GC_BANNER = 'pe-app/results/SCHEDULE_APPOINTMENT_CLICKED_GC_BANNER'
const CLICK_PREDICTED_FETAL_SEX_MODAL = 'pe-app/results/CLICK_PREDICTED_FETAL_SEX_MODAL'
const CLICK_I_AM_READY_IN_SEX_REVEAL = 'pe-app/results/CLICK_I_AM_READY_IN_SEX_REVEAL'
const CLICK_NOT_READY_IN_SEX_REVEAL = 'pe-app/results/CLICK_NOT_READY_IN_SEX_REVEAL'
const WATCH_SEX_REVEAL_VIDEO = 'pe-app/results/WATCH_SEX_REVEAL_VIDEO'
const SHARE_SEX_REVEAL_VIDEO = 'pe-app/results/SHARE_SEX_REVEAL_VIDEO'
const CLICK_READ_MORE_IN_RESULTS_SUMMARY = 'pe-app/results/CLICK_READ_MORE_IN_RESULTS_SUMMARY'
const VIEW_RESULTS_SUMMARY_PAGE = 'pe-app/results/VIEW_RESULTS_SUMMARY_PAGE'
const VIEW_DOCUMENTS_TAB = 'pe-app/results/VIEW_DOCUMENTS_TAB'
const DOWNLOAD_PATIENT_DOCUMENT = 'pe-app/results/DOWNLOAD_PATIENT_DOCUMENT'
const MARK_REPORT_RELEASE_START = 'pe-app/results/MARK_REPORT_RELEASE/START'
const MARK_REPORT_RELEASE_DONE = 'pe-app/results/MARK_REPORT_RELEASE/DONE'
const MARK_REPORT_RELEASE_FAILED = 'pe-app/results/MARK_REPORT_RELEASE/FAILED'

export const actionTypes = {
  CLICK_I_AM_READY_IN_SEX_REVEAL,
  CLICK_NOT_READY_IN_SEX_REVEAL,
  CLICK_PREDICTED_FETAL_SEX_MODAL,
  CLICK_READ_MORE_IN_RESULTS_SUMMARY,
  DISMISS_GC_BANNER,
  DOWNLOAD_PATIENT_DOCUMENT,
  GET_DOCUMENTS_DONE,
  GET_DOCUMENTS_START,
  GET_RECEIPTS_DONE,
  GET_RECEIPTS_START,
  GET_REQUISITION_DONE,
  GET_REQUISITION_START,
  GET_VARIANTS_DONE,
  GET_VARIANTS_START,
  INTERACTIVE_RESULTS_TABLE_ROW_TOGGLED,
  MARK_REPORT_RELEASE_DONE,
  MARK_REPORT_RELEASE_FAILED,
  MARK_REPORT_RELEASE_START,
  PROVIDER_SHARE_LINK_COPIED,
  REPORT_DOWNLOADED,
  SCHEDULE_APPOINTMENT_CLICKED_GC_BANNER,
  SCHEDULE_GC_APPOINTMENT,
  SET_UNREAD_ATTACHMENTS_COUNT,
  SHARE_COVERSHEET_PRINTED,
  SHARE_SEX_REVEAL_VIDEO,
  TALK_TO_GC_CLICKED_STATUS,
  UPDATE_REPORT_IS_DOWNLOADED,
  UPDATE_REQUISITION,
  UPDATE_REQUISITION_RECEIPTS,
  VIEW_DOCUMENTS_TAB,
  VIEW_RESULTS_SUMMARY_PAGE,
  WATCH_SEX_REVEAL_VIDEO,
}

const noOpAction = (state: any) => state

// actions below are used for analytics tracking
const trackingActions = {
  [CLICK_I_AM_READY_IN_SEX_REVEAL]: noOpAction,
  [CLICK_NOT_READY_IN_SEX_REVEAL]: noOpAction,
  [CLICK_PREDICTED_FETAL_SEX_MODAL]: noOpAction,
  [CLICK_READ_MORE_IN_RESULTS_SUMMARY]: noOpAction,
  [DISMISS_GC_BANNER]: noOpAction,
  [DOWNLOAD_PATIENT_DOCUMENT]: noOpAction,
  [SCHEDULE_APPOINTMENT_CLICKED_GC_BANNER]: noOpAction,
  [SCHEDULE_GC_APPOINTMENT]: noOpAction,
  [SHARE_SEX_REVEAL_VIDEO]: noOpAction,
  [TALK_TO_GC_CLICKED_STATUS]: noOpAction,
  [VIEW_DOCUMENTS_TAB]: noOpAction,
  [VIEW_RESULTS_SUMMARY_PAGE]: noOpAction,
  [WATCH_SEX_REVEAL_VIDEO]: noOpAction,
}

export default handleActions<IAsyncRequisitions, any>(
  {
    ...trackingActions,
    [GET_DOCUMENTS_DONE]: (
      state: IAsyncRequisitions,
      action: Action<{reqid: string; documents: IRequisitionAttachment[]}>,
    ) => {
      const {reqid, documents} = action.payload
      const {deserializeAttachments} = RequisitionTransformer
      const attachments = deserializeAttachments(documents)

      return {
        ...state,
        data: state.data.map((req: IRequisition) => {
          if (req.reqid === reqid) {
            return {
              ...req,
              attachments,
              unreadAttachmentsCount: getUnreadAttachmentsCount(req),
            }
          }
          return req
        }),
        isLoadingDocuments: false,
      }
    },
    [GET_DOCUMENTS_START]: (state: IAsyncRequisitions) => {
      return {
        ...state,
        isLoadingDocuments: true,
      }
    },
    [GET_RECEIPTS_DONE]: (
      state: IAsyncRequisitions,
      action: Action<{reqid: string; receipts: IRequisitionReceipt[]}>,
    ) => {
      const {reqid, receipts} = action.payload
      return {
        ...state,
        data: state.data.map((req: IRequisition) => {
          if (req.reqid === reqid) {
            return {...req, receipts}
          }
          return req
        }),
        isLoadingReceipts: false,
      }
    },
    [GET_RECEIPTS_START]: (state: IAsyncRequisitions) => {
      return {
        ...state,
        isLoadingReceipts: true,
      }
    },
    [GET_REQUISITION_DONE]: (state: IAsyncRequisitions, action: Action<{reqs: TRequisitions}>) => {
      return {
        ...state,
        data: action.payload.reqs,
        loadedAt: new Date(),
        loading: false,
      }
    },
    [GET_REQUISITION_START]: (state: IAsyncRequisitions, action: Action<{reqid: string}>) => {
      return {
        ...state,
        loading: true,
        requested: action.payload.reqid,
      }
    },
    [GET_VARIANTS_DONE]: (
      state: IAsyncRequisitions,
      action: Action<{
        reqid: string
        variants: IResultVariant[]
        resultServiceVariants: IResultServiceVariant[]
        preReportSimplifiedVariants?: IResultServiceVariant[]
      }>,
    ) => {
      const {reqid, variants, resultServiceVariants, preReportSimplifiedVariants} = action.payload

      return {
        ...state,
        data: state.data.map((req: IRequisition) => {
          if (req.reqid === reqid) {
            return {...req, preReportSimplifiedVariants, resultServiceVariants, variants}
          }
          return req
        }),
        isLoadingVariants: false,
      }
    },
    [GET_VARIANTS_START]: (state: IAsyncRequisitions) => {
      return {
        ...state,
        isLoadingVariants: true,
      }
    },
    [INTERACTIVE_RESULTS_TABLE_ROW_TOGGLED]: noOpAction,
    [PROVIDER_SHARE_LINK_COPIED]: noOpAction,
    [REPORT_DOWNLOADED]: noOpAction,
    [SET_UNREAD_ATTACHMENTS_COUNT]: (
      state: IAsyncRequisitions,
      action: Action<{reqid: string; unreadAttachmentsCount: number}>,
    ) => {
      return {
        ...state,
        data: state.data.map((req: IRequisition) => {
          if (req.reqid === action.payload.reqid) {
            return {...req, unreadAttachmentsCount: action.payload.unreadAttachmentsCount}
          }
          return req
        }),
      }
    },
    [SHARE_COVERSHEET_PRINTED]: noOpAction,
    showUnderstandingYourResultsModal: (
      state: IAsyncRequisitions,
      action: Action<{
        reqid: string
        show: boolean
      }>,
    ) => {
      return {
        ...state,
        data: state.data.map((req: IRequisition) => {
          if (req.reqid === action.payload.reqid) {
            req.showUnderstandingYourResultsModal = action.payload.show
          }
          return req
        }),
      }
    },
    [UPDATE_REPORT_IS_DOWNLOADED]: (
      state: IAsyncRequisitions,
      action: Action<{
        reqId: string
        reportId: number
      }>,
    ) => {
      const data = [...state.data]
      const req = data.find(({reqid}: IRequisition) => reqid === action.payload.reqId)
      const rep = req.reports.find(({id}) => id === action.payload.reportId)
      rep.is_downloaded = true

      return {
        ...state,
        data,
      }
    },
    [UPDATE_REQUISITION]: (state: IAsyncRequisitions, action: Action<{reqId: string; data: IRequisition}>) => {
      return {
        ...state,
        data: state.data.map((req: IRequisition) => {
          if (req.reqid === action.payload.reqId) {
            return {...req, ...action.payload.data}
          }
          return req
        }),
      }
    },
    [UPDATE_REQUISITION_RECEIPTS]: (
      state: IAsyncRequisitions,
      action: Action<{
        reqId: string
        receipts: any[]
      }>,
    ) => {
      return {
        ...state,
        data: state.data.map((req: IRequisition) => {
          if (req.reqid === action.payload.reqId) {
            const existingReceipts = req.receipts || []
            const newReceipts = RequisitionTransformer.deserializeReceipts(action.payload.receipts, req.patient_country)
            const receipts = [...existingReceipts, ...newReceipts]
            return {...req, receipts}
          }
          return req
        }),
      }
    },
  },
  {data: [], loading: false},
)

const getRequisitionsStartAction = createAction(GET_REQUISITION_START)
export const getRequisitionsDoneAction = createAction(GET_REQUISITION_DONE)
const getReceiptsStartAction = createAction(GET_RECEIPTS_START)
const getReceiptsDoneAction = createAction(GET_RECEIPTS_DONE)
const getVariantsStartAction = createAction(GET_VARIANTS_START)
const getVariantsDoneAction = createAction(GET_VARIANTS_DONE)
const updateReportIsDownloadedAction = createAction(UPDATE_REPORT_IS_DOWNLOADED)
const updateRequisitionAction = createAction(UPDATE_REQUISITION)
const updateRequisitionReceiptsAction = createAction(UPDATE_REQUISITION_RECEIPTS)
const setUnreadAttachmentsCountAction = createAction(SET_UNREAD_ATTACHMENTS_COUNT)

const dismissGCBannerAction = createAction(DISMISS_GC_BANNER)
const scheduleGCAppointment = createAction(SCHEDULE_GC_APPOINTMENT)

export const getDocumentsStartAction = createAction(GET_DOCUMENTS_START)
export const getDocumentsDoneAction = createAction(GET_DOCUMENTS_DONE)
export const reportDownloadedAction = createAction<IDownloadReportPayload>(REPORT_DOWNLOADED)
export const interactiveResultsTableRowToggledAction = createAction(INTERACTIVE_RESULTS_TABLE_ROW_TOGGLED)
export const providerShareLinkCopiedAction = createAction(PROVIDER_SHARE_LINK_COPIED)
export const shareCoversheetPrintedAction = createAction(SHARE_COVERSHEET_PRINTED)
export const talkToGCClickedStatusAction = createAction(TALK_TO_GC_CLICKED_STATUS)
export const scheduleAppointmentClickedGCBannerAction = createAction(SCHEDULE_APPOINTMENT_CLICKED_GC_BANNER)
export const clickPredictedFetalSexModalAction = createAction(CLICK_PREDICTED_FETAL_SEX_MODAL)
export const clickIAmReadyInSexRevealAction = createAction(CLICK_I_AM_READY_IN_SEX_REVEAL)
export const clickNotReadyInSexRevealAction = createAction(CLICK_NOT_READY_IN_SEX_REVEAL)
export const watchSexRevealVideoAction = createAction(WATCH_SEX_REVEAL_VIDEO)
export const shareSexRevealVideoAction = createAction(SHARE_SEX_REVEAL_VIDEO)
export const clickReadMoreInResultsSummaryAction = createAction(CLICK_READ_MORE_IN_RESULTS_SUMMARY)
export const viewResultsSummaryPageAction = createAction<IRequisitionPayload>(VIEW_RESULTS_SUMMARY_PAGE)
export const viewDocumentsTabAction = createAction(VIEW_DOCUMENTS_TAB)
export const downloadPatientDocumentAction = createAction<{attachment: IRequisitionAttachment; reqid: string}>(
  DOWNLOAD_PATIENT_DOCUMENT,
)
export const markReportReleaseStartAction = createAction(MARK_REPORT_RELEASE_START)
export const markReportReleaseDoneAction = createAction(MARK_REPORT_RELEASE_DONE)
export const markReportReleaseFailedAction = createAction(MARK_REPORT_RELEASE_FAILED)

/**
 * @typedef IGetRequisitionsOptions
 * @type {object}
 * @property {?boolean} setVisibility - defines if requisition should be displayed on dashboard
 * @property {?string} reqid - specify requisition to get
 * @property {?boolean} getReceipts - defines if receipts must be loaded
 * @property {?boolean} getVariants - defines if variants must be loaded
 */
export interface IGetRequisitionsOptions {
  reqid?: string
  update?: boolean
}

/**
 * Common function to request requisitions.
 * @param {IGetRequisitionsOptions} options
 * @returns {(dispatch) => void}
 */
export function getRequisitionsAction(options: IGetRequisitionsOptions = {}) {
  return async (dispatch: Dispatch<AppDispatch>, getState: () => IStore) => {
    const {reqid} = options
    const {loadedAt, data} = getState().requisitions
    let requisitions: IRequisition[]

    if (!loadedAt || reqid) {
      // loading requisitions if not loaded or update is required
      dispatch(getRequisitionsStartAction({reqid}))

      try {
        requisitions = await fetchRequisitions()
      } catch (e) {
        noticeError(e)
        dispatch(addNotification('Server error was detected. Please try again.'))
        return
      }

      dispatch(getRequisitionsDoneAction({reqs: requisitions}))
      dispatch(addCustomNotifications(requisitions))
    } else {
      // picking requisitions from store otherwise
      requisitions = data
    }

    // if no req id is provided or there's no req with provided id - leaving
    const req = reqid && requisitions.find((r: IRequisition) => r.reqid === reqid)
    if (!req) {
      return
    }

    // loading additional info otherwise
    const {
      requisitions: {isLoadingReceipts, isLoadingVariants},
    } = getState()

    if (!req.receipts && !isLoadingReceipts) {
      dispatch(getReceiptsAction(reqid, req.patient_country))
    }

    if (!req.variants && !isLoadingVariants) {
      dispatch(getVariantsAction(reqid))
    }

    if (req.isCarrier && !req.patientIsPartner) {
      dispatch(getPartnerLinkAction(reqid))
    }
  }
}

export function getRequisitionAdditionalInfoAction(req: IRequisition) {
  return async (dispatch: Dispatch<AppDispatch>, getState: () => IStore) => {
    const {requisitions} = getState()
    const {isLoadingReceipts, isLoadingVariants} = requisitions

    dispatch(getRequisitionsStartAction({reqid: req.reqid}))

    if (!req.receipts && !isLoadingReceipts) {
      dispatch(getReceiptsAction(req.reqid, req.patient_country))
    }

    if (!req.variants && !isLoadingVariants) {
      dispatch(getVariantsAction(req.reqid))
    }

    if (req.isCarrier && !req.patientIsPartner) {
      dispatch(getPartnerLinkAction(req.reqid))
    }

    dispatch(getRequisitionsDoneAction({reqs: requisitions.data}))
  }
}

export function getDocumentsAction(reqid: string) {
  return async (dispatch: Dispatch, getState: () => IStore) => {
    dispatch(getDocumentsStartAction())

    try {
      const documents = await fetchDocuments(reqid)
      const featureFlags = getState().featureFlags.data
      dispatch(getDocumentsDoneAction({documents, featureFlags, reqid}))
    } catch (e) {
      noticeError(e)
      dispatch(addNotification('Server error was detected. Please try again.'))
    }
  }
}

export const getReceiptsAction =
  (reqid: string, patientCountry: string = COUNTRY_US) =>
  async (dispatch: Dispatch<AppDispatch>) => {
    dispatch(getReceiptsStartAction())

    try {
      const receipts = await fetchReceipts(reqid, patientCountry)
      dispatch(getReceiptsDoneAction({receipts, reqid}))
    } catch (e) {
      noticeError(e)
      dispatch(addNotification('Server error was detected. Please try again.'))
    }
  }

export const getVariantsAction = (reqid: string) => async (dispatch: Dispatch<AppDispatch>) => {
  dispatch(getVariantsStartAction())

  try {
    const {variants, resultServiceVariants, preReportSimplifiedVariants} = await fetchVariants(reqid)
    dispatch(getVariantsDoneAction({preReportSimplifiedVariants, reqid, resultServiceVariants, variants}))
  } catch (e) {
    noticeError(e)
    dispatch(addNotification('Server error was detected. Please try again.'))
  }
}

export function updateReportIsDownloaded(reqId: string, reportId: number) {
  return updateReportIsDownloadedAction({reportId, reqId})
}

export function updateRequisition(reqId: string, data: Record<string, unknown>) {
  return updateRequisitionAction({data, reqId})
}

export function updateRequisitionReceipts(reqId: string, receipts: any[]) {
  return updateRequisitionReceiptsAction({receipts, reqId})
}

export function setAttachmentsAsViewed(requisition: IRequisition) {
  setViewedAttachmentsIDs(requisition)
  return setUnreadAttachmentsCountAction({reqid: requisition.reqid, unreadAttachmentsCount: 0})
}

export interface IDismissGCBanner {
  didSchedule: boolean
  reqid: string
  option?: string
}

export const dismissGCBanner =
  ({didSchedule, reqid, option}: IDismissGCBanner) =>
  async (dispatch: Dispatch<AppDispatch>) => {
    await postGeneticCounselingDismissed(reqid, didSchedule, option)
    if (didSchedule) {
      dispatch(scheduleGCAppointment({reqid}))
    } else {
      dispatch(dismissGCBannerAction({option, reqid}))
    }
  }

export const markSexRevealVideoAsWatched = (reqId: string) => (dispatch: Dispatch<AppDispatch>) =>
  markSexRevealVideoAsWatchedRequest(`RQ${reqId}`).then(() => {
    dispatch(updateRequisitionAction({data: {has_sex_reveal_video_watched: true}, reqId}))
    dispatch(watchSexRevealVideoAction({reqid: reqId}))
  })

export const markReportReleaseAsViewed = (reqid: string, reportId: number) => (dispatch: Dispatch<AppDispatch>) => {
  dispatch(markReportReleaseStartAction())
  return postMarkReportReleaseAsViewed(reportId)
    .then(() => {
      dispatch(markReportReleaseDoneAction())
      dispatch(updateRequisitionAction({data: {hasPatientViewedResults: true}, reqid}))
    })
    .catch(error => {
      noticeError(error)
      dispatch(markReportReleaseFailedAction())
    })
}
