import {AxiosResponse} from 'axios'
import {IFeatureFlagsData} from 'models/FeatureFlags'

import {FeatureFlagsResponseDto} from '@invitae/stargate'

import {FEATURE_FLAG_NAME} from 'constants/featureFlags'

export function featureFlagsDeserialize(response: AxiosResponse<FeatureFlagsResponseDto>): IFeatureFlagsData {
  const flags: Partial<IFeatureFlagsData> = {}

  for (const flag of response.data.featureFlags) {
    flags[flag.name as FEATURE_FLAG_NAME] = flag.enabled
  }

  return flags as IFeatureFlagsData
}
